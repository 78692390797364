import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import Geocode from 'react-geocode';

// REDUX
import { connect } from 'react-redux';
import {
  getPois,
  clearPois,
  filterPois,
  savePoiToDB,
  deletePoiFromDB,
  setError
} from '../../redux/actions/pois/poisActions';
import { addPoiToTemplate } from '../../redux/actions/tourTemplates/tourTemplatesActions';

import SweetAlert from 'react-bootstrap-sweetalert';

// CUSTOM COMPONENTS
import Error from '../../components/Error/Error';
import PoisList from '../../components/PoisList/PoisList';

// CUSTOM DATA
import { INITIAL_POI } from '../../shared/utils/utils';

// CUSTOM SCSS
import './Pois.scss';

class Pois extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      description: '',
      title: '',
      editable: true,
      selectedPoi: INITIAL_POI,
      searchTerm: '',
      editMode: false,
      selectedCategory: '',
      confirmDialogOpen: false,
      poiToDelete: null
    };

    Geocode.setApiKey('AIzaSyBN63jlOf5AOckC6cg9KafHCBPwAj2GvJo');
    // set response language. Defaults to english.
    Geocode.setLanguage('el');
    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion('el');
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
  }

  componentDidMount() {
    // Loads initial data
    this.props.getPois();
  }

  componentDidUpdate(prevProps) {
    const { saved, deleted } = this.props.pois;
    // Check if there is a saved/deleted poi to start fetch pois from the beginning otherwise add new pois to the previous ones
    if (
      (saved !== prevProps.pois.saved && saved) ||
      (deleted !== prevProps.pois.deleted && deleted)
    ) {
      this.props.clearPois();
      this.props.getPois();
    }
  }

  componentWillUnmount() {
    this.props.clearPois();
  }

  /**
   * Save new/edited poi to DB
   */
  savePoi = async(poi) => {
    console.log('poi', poi);
    this.props.clearPois();
    await this.props.savePoiToDB({ poi: poi, update: this.state.editMode });
    this.toggle();
    this.setState({ editMode: false });
    if(poi.isBusiness) {
      this.props.history.push({ 
        pathname: '/business',
        state: { poiData: poi }
      });
    }
  };

  /**
   * Sets selected poi
   *
   * @param {*} item
   * @param {*} editable
   */
  onItemClick = (item, editable) => {
    this.setState(
      {
        selectedPoi: item,
        editMode: true,
        editable
      },
      () => {
        this.setState({ modal: true });
      }
    );
  };

  /**
   * Deletes poi from DB
   *
   * @param {*} poi
   */
  onItemDelete = () => {
    console.log('going to delete poi', this.state.poiToDelete);
    this.props.deletePoiFromDB(this.state.poiToDelete);
    this.setState({ confirmDialogOpen: false });
  };

  openConfirmationDialog = (poi) => {
    this.setState({ confirmDialogOpen: true, poiToDelete: poi });
  };

  onCancel = () => {
    this.setState({ confirmDialogOpen: false });
  };

  /**
   * Toggles poi's modal form
   *
   */
  toggle = () => {
    this.setState((prevState) => {
      //when you close the modal, clear the previous changes
      if (prevState.modal) {
        return {
          modal: !prevState.modal,
          selectedPoi: INITIAL_POI,
          editMode: false
        };
      } else {
        return { modal: !prevState.modal };
      }
    });
  };

  /**
   * Handles changes from poi's form
   *
   * @param {*} event
   */
  handleChange = (event) => {
    const value = event.target.value;
    const checkedValue = event.target.checked
    const name = event.target.name;

    this.setState((prevState) => {
      let tempPoi = Object.assign({}, prevState.selectedPoi); // creating copy of state variable

      if (name === 'title_en') {
        tempPoi.nameTranslations = {
          ...prevState.selectedPoi.nameTranslations,
          en: value
        };
      } else if (name === 'title_el') {
        tempPoi.nameTranslations = {
          ...prevState.selectedPoi.nameTranslations,
          el: value
        };
      } else if (name === 'description_en') {
        tempPoi.descriptionTranslations = {
          ...prevState.selectedPoi.descriptionTranslations,
          en: value
        };
      } else if (name === 'description_el') {
        tempPoi.descriptionTranslations = {
          ...prevState.selectedPoi.descriptionTranslations,
          el: value
        };
      } else if (name === 'proposedDuration') {
        let calcDuration = value;
        if (value < 3600) {
          calcDuration = calcDuration * 60;
        }
        tempPoi[name] = calcDuration;
      } else if (name === 'isBusiness') {
        let isBusinessValue = checkedValue;
        tempPoi[name] = isBusinessValue;
      } else {
        tempPoi[name] = value;
      }

      return { selectedPoi: tempPoi };
    });
  };

  /**
   * Handles changes in category filter from poi's form
   *
   * @param {*} event
   */
  handleCategoryFilter = (event) => {
    const value = event.target.value;

    console.log('filter by category: ' + value);

    this.props.clearPois();
    this.setState(
      {
        searchTerm: value,
        pois: [],
        disabledScroll: value !== '',
        previousScrollTop: 0,
        page: 0,
        selectedCategory: value
      },
      () => {
        if (value) {
          this.props.getPois({ categories: value });
        } else {
          this.props.getPois();
        }
      }
    );
  };

  /**
   * Closes error message
   *
   */
  clearError = () => {
    this.props.setError('');
  };

  /**
   * Clears search term
   *
   */
  clearPois = () => {
    this.props.clearPois();
    this.setState(
      {
        searchTerm: '',
        pois: [],
        disabledScroll: false,
        previousScrollTop: 0,
        page: 0
      },
      () => {
        this.filterPois('');
      }
    );
  };

  /**
   * Filters pois by search term
   *
   */
  filterPois = (term) => {
    if (term === this.state.searchTerm) {
      return;
    }

    this.props.clearPois();
    this.setState(
      {
        searchTerm: term,
        pois: [],
        disabledScroll: term !== '',
        previousScrollTop: 0,
        page: 0
      },
      () => {
        if (term) {
          this.props.filterPois(term);
        } else {
          this.props.getPois();
        }
      }
    );
  };

  render() {
    return (
      <Container className="dashboard pois_dashboard">
        <Row>
          <Col>
            <div className="page_title_container">
              <h3 className="page_title">List of POIs</h3>
              <h4 className="page_subtitle">
                In this section you can edit saved POIs or create new ones.
              </h4>
            </div>

            {this.state.confirmDialogOpen && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={this.onItemDelete}
                onCancel={this.onCancel}
                focusCancelBtn
              >
                You will not be able to undo this action!
              </SweetAlert>
            )}

            <PoisList
              pois={this.props.pois}
              modal={this.state.modal}
              editable={this.state.editable}
              selectedCategory={this.state.selectedCategory}
              selectedPoi={this.state.selectedPoi}
              toggle={this.toggle}
              onItemDelete={this.openConfirmationDialog}
              onItemClick={(poi) => this.onItemClick(poi, true)}
              handleChange={(event) => this.handleChange(event)}
              handleCategoryFilter={(event) => this.handleCategoryFilter(event)}
              handleFilterByCategory={(event) =>
                this.handleCategoryFilter(event)
              }
              savePoi={(poi) => this.savePoi(poi)}
              btn="New POI"
              xl={3}
              lg={4}
              md={6}
              s={12}
            ></PoisList>
          </Col>
        </Row>

        <Error
          error={this.props.pois.error}
          page={true}
          onErrorClose={() => this.clearError()}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pois: state.pois,
    title: state.title
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPois: (payload) => dispatch(getPois(payload)),
    clearPois: (payload) => dispatch(clearPois(payload)),
    filterPois: (payload) => dispatch(filterPois(payload)),
    savePoiToDB: (payload) => dispatch(savePoiToDB(payload)),
    deletePoiFromDB: (payload) => dispatch(deletePoiFromDB(payload)),
    addPoiToTemplate: (payload) => dispatch(addPoiToTemplate(payload)),
    setError: (payload) => dispatch(setError(payload))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pois));
