import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  customTabIndicator: {
    backgroundColor: '#eb5b41',
  },
  customTabText: {
    color: '#eb5b41',
  },
});

const CustomTabs = ({ classes, tabsData, value, onChange, ariaLabel }) => {

    function a11yProps(index) {
        return {
          id: `wrapped-tab-${index}`,
          'aria-controls': `wrapped-tabpanel-${index}`,
        };
      }

  return (
    <Tabs
        value={value}
        onChange={onChange}
        aria-label={ariaLabel}
        classes={{
        indicator: classes.customTabIndicator,
      }}
    >
        {tabsData?.map((tab, index) => (
            <Tab
                classes={{
                textColorInherit: classes.customTabText,
                }}
                label={tab.label}
                {...a11yProps(index)}
            />)
        )}
      
      {/* Add more tabs as needed */}
    </Tabs>
  );
};

export default withStyles(styles)(CustomTabs);