import dataApi from '../../../services/data/dataApi';

// CUSTOM DATA
import { LOCALE } from '../../../shared/utils/utils';

export const FETCH_AMOUNT_DATA = 'FETCH_AMOUNT_DATA';
export const FETCH_POIS_SUCCESSFUL = 'FETCH_POIS_SUCCESSFUL';
export const FETCH_BUSINESS_POIS_SUCCESSFUL = 'FETCH_BUSINESS_POIS_SUCCESSFUL';
export const POIS_API_ERROR = 'POIS_API_ERROR';
export const SAVE_POI_SUCCESSFUL = 'SAVE_POI_SUCCESSFUL';
export const DELETE_POI_SUCCESSFUL = 'DELETE_POI_SUCCESSFUL';
export const POIS_LOADING = 'POIS_LOADING';
export const TOGGLE_POI_INFO_WINDOW = 'TOGGLE_POI_INFO_WINDOW';
export const BUSINESS_POIS_LOADING = "BUSINESS_POIS_LOADING";
export const FETCH_SUBSCRIPTIONS_SUCCESSFUL = "FETCH_SUBSCRIPTIONS_SUCCESSFUL";
export const FETCH_LOYALTIES_SUCCESSFUL = "FETCH_LOYALTIES_SUCCESSFUL";
export const CLEAR_SAVED_POI = "CLEAR_SAVED_POI";
export const INSERT_AD_SUCCESSFUL = "INSERT_AD_SUCCESSFUL";
export const FETCH_POI_AD_SUCCESSFUL = "FETCH_POI_AD_SUCCESSFUL";
export const DELETE_AD = "DELETE_AD";
export const CLEAR_POI_AD = "CLEAR_POI_AD";

function poisLoading(payload) {
  console.log('calling POIS_LOADING', payload);
  return {
    type: POIS_LOADING,
    payload,
  };
}

function businessPoisLoading(payload) {
  console.log('calling POIS_LOADING', payload);
  return {
    type: BUSINESS_POIS_LOADING,
    payload,
  };
}

function setData(payload) {
  console.log('calling setData', payload);
  return {
    type: FETCH_POIS_SUCCESSFUL,
    payload,
  };
}

function setBusinessData(payload) {
  console.log('calling setData', payload);
  return {
    type: FETCH_BUSINESS_POIS_SUCCESSFUL,
    payload,
  };
}

function savePoi(payload) {
  console.log('calling savePoi', payload);
  return {
    type: SAVE_POI_SUCCESSFUL,
    payload,
  };
}

function saveAd(payload) {
  console.log('calling saveAd', payload);
  return {
    type: INSERT_AD_SUCCESSFUL,
    payload
  }
}

function setSinglePoiAd(payload) {
  console.log('calling setPoiAd', payload);
  return {
    type: FETCH_POI_AD_SUCCESSFUL,
    payload
  }
}

function deletePoi() {
  console.log('calling deletePoi');
  return {
    type: DELETE_POI_SUCCESSFUL,
  };
}

function deleteAd() {
  console.log('calling deleteAd');
  return {
    type: DELETE_AD,
  };
}

export function togglePoiInfoWindow(payload) {
  console.log('calling togglePoiInfoWindow', payload);
  return {
    type: TOGGLE_POI_INFO_WINDOW,
    payload,
  };
}

export function setError(payload) {
  return {
    type: POIS_API_ERROR,
    payload,
  };
}

export const setSubscriptions = (payload) => {
  return {
    type: FETCH_SUBSCRIPTIONS_SUCCESSFUL,
    payload
  }
}

export const setLoyalties = (payload) => {
  return {
    type: FETCH_LOYALTIES_SUCCESSFUL,
    payload
  }
}

export const clearSavedPoi = (payload) => {
  return {
    type: CLEAR_SAVED_POI,
  }
}

export const clearPoiAd = (payload) => {
  return {
    type: CLEAR_POI_AD
  }
}

export function getPois(payload) {
  return async (dispatch) => {
    dispatch(poisLoading());
    try {
      const params = { ...payload, locale: LOCALE, isBusiness: false, isActive: false };
      const response = await dataApi.fetch('pois', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = setData(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function getBusinessPois(payload) {
  return async (dispatch) => {
    dispatch(businessPoisLoading());
    console.log("payload", payload);
    try {
      const params = { ...payload, locale: LOCALE};
      const response = await dataApi.fetch('pois', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = setBusinessData(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function clearPois() {
  return async (dispatch) => {
    dispatch(setData([]));
  };
}

export function filterPois(payload) {
  return async (dispatch) => {
    dispatch(poisLoading());
    try {
      const params = payload;
      const response = await dataApi.fetch('filterPois', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        if (response.data.length > 0) {
          dispatch(setData(response.data));
        } else {
          dispatch(setError('No data found for this search term'));
        }
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function savePoiToDB(payload) {
  console.log('calling savePoiToDB...');

  return async (dispatch) => {
    try {
      dispatch(poisLoading());
      let response;
      if (payload.update) {
        response = await dataApi.update('pois', payload.poi);
      } else {
        response = await dataApi.post('pois', payload.poi);
      }
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = savePoi(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
        return response.data;
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
        return null
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
      return null
    }
  };
}

export function insertAdToDB(payload) {
  console.log('calling insertAdToDB...');
  return async (dispatch) => {
    try {
      let response;
      if (payload.edit) {
        response = await dataApi.update('poiAd', payload.ad);
      } else {
        response = await dataApi.post('poiAd', payload.ad);
      }
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = saveAd(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
        
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function fetchPoiAdById(payload) {
  return async (dispatch) => {
    try {
      let response;
        response = await dataApi.fetch('poiAd', payload.id);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = setSinglePoiAd(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        // dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      // dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function deleteAdFromDB(payload) {
  console.log('calling deleteAdFromDB...');
  return async (dispatch) => {
    try {
      const response = await dataApi.remove('poiAd', payload);
      if (response.status === 200) {
        console.log('deleteAdFromDB response:', response);
        let action = deleteAd();
        console.log('deleteAdFromDB action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function deletePoiFromDB(payload) {
  console.log('calling deletePoiFromDB...');

  return async (dispatch) => {
    try {
      dispatch(poisLoading());

      const response = await dataApi.remove('pois', payload);
      if (response.status === 204) {
        console.log('deletePoiFromDB response:', response);
        let action = deletePoi();
        console.log('deletePoiFromDB action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export function fetchAdditionalPois(payload) {
  return async (dispatch) => {
    dispatch(poisLoading());
    try {
      const params = { ...payload, locale: LOCALE };
      const response = await dataApi.fetch('pois', params);
      if (response.status === 200) {
        console.log('inside Action:', response);
        let action = setData(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  };
}

export const fetchAllSubscriptions = (payload) => {
  return async (dispatch) => {
    try {
      const params = {...payload};
      const response = await dataApi.fetch('subscriptions', params);
      if(response.status === 200) {
        console.log('inside Action:', response);
        let action = setSubscriptions(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  }
}

export const fetchAllLoyalties = (payload) => {
  return async (dispatch) => {
    try {
      const params = {...payload};
      const response = await dataApi.fetch('loyalties', params);
      if(response.status === 200) {
        console.log('inside Action:', response);
        let action = setLoyalties(response.data);
        console.log('action to dispatch', action);
        dispatch(action);
      } else {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    } catch (error) {
      dispatch(setError('Something went wrong. Please try later!'));
    }
  }
}
