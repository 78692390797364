import React from 'react'
import LoyaltiesForm from '../Loyalties/LoyaltiesForm';
import SubscriptionsForm from '../Subscriptions/SubscriptionsForm';
import GainPointsForm from '../GainPoints/GainPointsForm';
import RedeemPointsForm from '../RedeemPoints/RedeemPointsForm';

const PlanItemForm = (props) => {
    const {actionType, canEdit, item, handleChangeEvent, toggle} = props;

  return (
    <>
      {actionType === "Subscription" ? <SubscriptionsForm toggle={toggle} canEdit={canEdit} item={item} handleChangeEvent={handleChangeEvent} /> 
        : actionType === "Loyalty" ? <LoyaltiesForm toggle={toggle} canEdit={canEdit} item={item} handleChangeEvent={handleChangeEvent} /> 
        : actionType === "GainPoints" ? <GainPointsForm toggle={toggle} canEdit={canEdit} item={item} handleChangeEvent={handleChangeEvent} /> 
        : actionType === "RedeemPoints" ? <RedeemPointsForm toggle={toggle} canEdit={canEdit} item={item} handleChangeEvent={handleChangeEvent} /> : null }
    </>
  )
}

export default PlanItemForm
