import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import Geocode from 'react-geocode';

// REDUX
import { connect } from 'react-redux';
import {
getBusinessPois,
  clearPois,
  filterPois,
  savePoiToDB,
  deletePoiFromDB,
  setError,
  fetchAllSubscriptions,
  fetchAllLoyalties,
  clearSavedPoi,
  insertAdToDB,
  fetchPoiAdById,
  deleteAdFromDB,
  clearPoiAd
} from '../../redux/actions/pois/poisActions';
import { addPoiToTemplate } from '../../redux/actions/tourTemplates/tourTemplatesActions';

import SweetAlert from 'react-bootstrap-sweetalert';

// CUSTOM COMPONENTS
import Error from '../../components/Error/Error';
import PoisList from '../../components/PoisList/PoisList';

// CUSTOM DATA
import { INITIAL_POI } from '../../shared/utils/utils';

// CUSTOM SCSS
import "../Pois/Pois.scss"
import BusinessPoisList from '../../components/BusinessPoiList/BusinessPoiList';
import Loader from '../../components/Loader/Loader';
import { CircularProgress } from '@material-ui/core';

class BusinessPois extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      description: '',
      title: '',
      editable: true,
      selectedPoi: INITIAL_POI,
      searchTerm: '',
      editMode: false,
      selectedCategory: '',
      confirmDialogOpen: false,
      poiToDelete: null,
      activeBusiness: false,
      poiDataFromHistory: null,
      loadingDataFromHistory: false,
      loaderProgress: 0,
      effectiveConnectionType: 'Calculating...',
    };

    Geocode.setApiKey('AIzaSyBN63jlOf5AOckC6cg9KafHCBPwAj2GvJo');
    // set response language. Defaults to english.
    Geocode.setLanguage('el');
    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion('el');
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
  }

  componentDidMount() {
    // Loads initial data
    this.props.getPois({isBusiness: true, isActive: this.state.activeBusiness});
    this.props.getAllSubscriptions();
    this.props.getAllLoyalties();
    if (this.props.location.state && this.props.location.state.poiData) {
      // Retrieve poiData from location.state
      const { poiData } = this.props.location.state;
      // Clear location.state after retrieval
      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {},
      });
      this.setState({
        poiDataFromHistory: poiData,
        loadingDataFromHistory: true
      });
      this.animateProgress()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { saved, deleted } = this.props.pois;
    // Check if there is a saved/deleted poi to start fetch pois from the beginning otherwise add new pois to the previous ones
    if (
      (saved !== prevProps.pois.saved && saved) ||
      (deleted !== prevProps.pois.deleted && deleted)
    ) {
      this.props.clearPois();
      this.props.getPois({isBusiness: true, isActive: this.state.activeBusiness});
    }

    if(this.state.poiDataFromHistory !== null && this.state.loaderProgress === 100 && this.props.pois?.businessData?.filter( item => item.nameTranslations?.en ===  this.state.poiDataFromHistory.nameTranslations?.en).length === 1) {
      console.log('poiDataFromHistory', this.state.poiDataFromHistory)
      let myData = this.props.pois?.businessData?.filter(item => item.nameTranslations?.en === this.state.poiDataFromHistory.nameTranslations?.en);
      this.onItemClick(myData[0], true);
    }
  }

  componentWillUnmount() {
    this.props.clearPois();
  }


  /**
   * Make custom animation for progress to show, when we redirected from poi page with poiData
   */
  animateProgress() {
    const intervalId = setInterval(() => {
      if (this.state.loaderProgress < 100) {
        this.setState((prevState) => {
          console.log('prevState.loaderProgress', prevState.loaderProgress);
          return{
            loaderProgress: prevState.loaderProgress + 10,
          }
        });
      } else {
        clearInterval(intervalId);
      }
    }, 200); // Adjust the interval duration for smoother animation
  }

  /**
   * Save new/edited poi to DB
   */
  savePoi = async(data) => {
    this.props.clearPois();
    this.props.clearSavedPoi();
    const poiSavedData = await this.props.savePoiToDB({ poi: data.poi, update: this.state.editMode });
    if(poiSavedData !== null && poiSavedData !== undefined) {
      const adPayload = {
        ...data.ad, 
        poiId: poiSavedData.id
      }
      if(Object.keys(this.props.poiAd).length > 0 && this.props.poiAd.id !== null) {
        if(adPayload.poiUrl === "" && adPayload.bannerUrl === "") {
          await this.props.deleteAdFromDB(adPayload.id)
        } else {
          await this.props.saveAdPoiToDB({ad: adPayload, edit: true})
        }
      } else {
        await this.props.saveAdPoiToDB({ad: adPayload, edit: false})
      }
      
      this.toggle();
      this.setState({ editMode: false });
      this.setState({poiDataFromHistory: null});
    }
  };

  /**
   * Sets selected poi
   *
   * @param {*} item
   * @param {*} editable
   */
  onItemClick = (item, editable) => {
    this.props.clearPoiAd();
    this.props.getPoiAdById({id: item.id})
    this.setState(
      {
        selectedPoi: item,
        editMode: true,
        editable
      },
      () => {
        this.setState({
          modal: true,
          loadingDataFromHistory: false,
          poiDataFromHistory: null
        });
      }
    );
  };

  /**
   * Deletes poi from DB
   *
   * @param {*} poi
   */
  onItemDelete = () => {
    console.log('going to delete poi', this.state.poiToDelete);
    this.props.deletePoiFromDB(this.state.poiToDelete);
    this.setState({ confirmDialogOpen: false });
  };

  openConfirmationDialog = (poi) => {
    this.setState({ confirmDialogOpen: true, poiToDelete: poi });
  };

  onCancel = () => {
    this.setState({ confirmDialogOpen: false, poiDataFromHistory: null });
  };

  /**
   * Toggles poi's modal form
   *
   */
  toggle = () => {
    this.setState((prevState) => {
      //when you close the modal, clear the previous changes
      if (prevState.modal) {
        return {
          modal: !prevState.modal,
          selectedPoi: INITIAL_POI,
          editMode: false
        };
      } else {
        return { modal: !prevState.modal };
      }
    });
  };

  /**
   * Handles changes from poi's form
   *
   * @param {*} event
   */
  handleChange = (event) => {
    const value = event.target.value;
    const checkedValue = event.target.checked
    const name = event.target.name;

    this.setState((prevState) => {
      let tempPoi = Object.assign({}, prevState.selectedPoi); // creating copy of state variable

      if (name === 'title_en') {
        tempPoi.nameTranslations = {
          ...prevState.selectedPoi.nameTranslations,
          en: value
        };
      } else if (name === 'title_el') {
        tempPoi.nameTranslations = {
          ...prevState.selectedPoi.nameTranslations,
          el: value
        };
      } else if (name === 'description_en') {
        tempPoi.descriptionTranslations = {
          ...prevState.selectedPoi.descriptionTranslations,
          en: value
        };
      } else if (name === 'description_el') {
        tempPoi.descriptionTranslations = {
          ...prevState.selectedPoi.descriptionTranslations,
          el: value
        };
      } else if (name === 'proposedDuration') {
        let calcDuration = value;
        if (value < 3600) {
          calcDuration = calcDuration * 60;
        }
        tempPoi[name] = calcDuration;
      } else if (name === 'isBusiness') {
        let isBusinessValue = checkedValue;
        tempPoi[name] = isBusinessValue;
      } else {
        tempPoi[name] = value;
      }

      return { selectedPoi: tempPoi };
    });
  };

  /**
   * Handles changes in category filter from poi's form
   *
   * @param {*} event
   */

  /**
   * Closes error message
   *
   */
  clearError = () => {
    this.props.setError('');
  };


  handleAddingActiveBusiness = (subscribedBusiness) => {
    console.log('subscribed only', subscribedBusiness);
    this.setState({activeBusiness: subscribedBusiness})
    this.props.getPois({isBusiness: true, isActive: subscribedBusiness});
  }

  render() {
    return (
      <Container className="dashboard pois_dashboard">
        <Row>
          <Col>
            <div className="page_title_container">
              <h3 className="page_title">List of Businesses</h3>
              <h4 className="page_subtitle">
                In this section you can edit saved Businesses or create new ones.
              </h4>
            </div>

            {this.state.confirmDialogOpen && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={this.onItemDelete}
                onCancel={this.onCancel}
                focusCancelBtn
              >
                You will not be able to undo this action!
              </SweetAlert>
            )}
            {this.state.loadingDataFromHistory && <>
              <div className="business-page__load-redirected" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <span>Loading Data from POI page...</span>
                <CircularProgress className='redirected-data-loader' variant='determinate' value={this.state.loaderProgress} size={30} />
              </div>
            </>
            }
            <BusinessPoisList
              pois={this.props.pois}
              modal={this.state.modal}
              editable={this.state.editable}
              selectedPoi={this.state.selectedPoi}
              toggle={this.toggle}
              onItemDelete={this.openConfirmationDialog}
              onItemClick={(poi) => this.onItemClick(poi, true)}
              handleChange={(event) => this.handleChange(event)}
              showSubOnly={this.state.activeBusiness}
              showActiveBusiness={this.handleAddingActiveBusiness}
              savePoi={(poi) => this.savePoi(poi)}
              btn="New Business"
              xl={3}
              lg={4}
              md={6}
              s={12}
            ></BusinessPoisList>
          </Col>
        </Row>

        <Error
          error={this.props.pois.error}
          page={true}
          onErrorClose={() => this.clearError()}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pois: state.pois,
    title: state.title,
    savedPoi: state.pois.savedPoi,
    poiAd: state.pois.poiAd,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPois: (payload) => dispatch(getBusinessPois(payload)),
    clearPois: (payload) => dispatch(clearPois(payload)),
    filterPois: (payload) => dispatch(filterPois(payload)),
    savePoiToDB: (payload) => dispatch(savePoiToDB(payload)),
    deletePoiFromDB: (payload) => dispatch(deletePoiFromDB(payload)),
    setError: (payload) => dispatch(setError(payload)),
    getAllSubscriptions: (payload) => dispatch(fetchAllSubscriptions(payload)),
    getAllLoyalties: (payload) => dispatch(fetchAllLoyalties(payload)),
    clearSavedPoi: (payload) => dispatch(clearSavedPoi(payload)),
    saveAdPoiToDB: (payload) => dispatch(insertAdToDB(payload)),
    getPoiAdById: (payload) => dispatch(fetchPoiAdById(payload)),
    deleteAdFromDB: (payload) => dispatch(deleteAdFromDB(payload)),
    clearPoiAd: (payload) => dispatch(clearPoiAd(payload))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessPois));
