import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../Layout/Layout';

// Use tour page instead of home
import Home from '../../Home/Home';
import Pois from '../../Pois/Pois';
import Statistics from '../../Statistics/Statistics';
import TourTemplates from './TourTemplates';
import Businesses from '../../Business/Businesses';
import Users from '../../Users/Users';
import ManagePlans from '../../ManagePlans/ManagePlans';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={TourTemplates} />
      <Route exact path="/business" component={Businesses} />
      <Route exact path="/manage-plans" component={ManagePlans} />
      <Route path="/pois" component={Pois} />
      <Route path="/tourTemplates" component={TourTemplates} />
      <Route path="/users" component={Users} />
      <Route path="/statistics" component={Statistics} />
    </div>
  </div>
);
