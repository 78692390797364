import React from 'react';
import { Button } from 'reactstrap';

// CUSTOM DATA
import { CATEGORΥ_NAMES } from '../../shared/utils/utils';

// ICONS
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import VisibilityIcon from 'mdi-react/VisibilityIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import ArrowDropUpIcon from 'mdi-react/ArrowDropUpIcon';
import ArrowDropDownIcon from 'mdi-react/ArrowDropDownIcon';

// CUSTOM IMAGE
import NoImage from '../../shared/img/noImage.png';

// CUSTOM SCSS
import './Poi.scss';

const Poi = props => {
  const calculateDuration = duration => {
    let calcDuration;
    if (duration > 3600) {
      let totalMinutes = Math.floor(duration / 60);
      let remainingMinutes = totalMinutes % 60;
      let totalHours = Math.floor(duration / 3600);
      calcDuration =
        totalHours +
        'h ' +
        (remainingMinutes === 0 ? '' : remainingMinutes + 'm');
    } else {
      calcDuration = Math.floor(duration / 60) + 'm';
    }
    return calcDuration;
  };

  const addDefaultSrc = ev => {
    console.log('addDefaultSrc');
    ev.target.src = NoImage;
  };

  return (
    <div className="poi_container d-flex align-items-center">
      {props.onUpClick && props.onDownClick && (
        <div className="sort_container d-flex flex-column justify-content-center align-items-center">
          <Button disabled={props.start} onClick={() => props.onUpClick()}>
            <ArrowDropUpIcon />
          </Button>
          <Button disabled={props.finish} onClick={() => props.onDownClick()}>
            <ArrowDropDownIcon />
          </Button>
        </div>
      )}
      <div className="poi_img">
        <img
          onError={addDefaultSrc}
          src={props.poi.photoURL}
          alt={props.poi.name}
        />
      </div>
      <div className="poi">
        <div className="poi_title">
          {/* {(props.start || props.finish) && (
            <Badge className="poi_label">
              {props.start ? 'START' : 'FINISH'}
            </Badge>
          )} */}
          <span className="text_ellipsis">{props.poi.name}</span>
        </div>
        <div className="duration">
          {calculateDuration(props.poi.proposedDuration)}
        </div>
        <div>
          <span className="text_ellipsis">{props.poi.address}</span>
        </div>
        <div>{CATEGORΥ_NAMES[props.poi.type]}</div>
      </div>
      <div className="vertical_buttons d-flex flex-column justify-content-center">
        {props.onRemoveClick && (
          <Button onClick={() => props.onRemoveClick()} className="remove_btn">
            <MinusIcon />
          </Button>
        )}
        {props.onViewClick && (
          <Button
            className="btn-view-poi-details"
            onClick={() => props.onViewClick()}
          >
            <VisibilityIcon />
          </Button>
        )}
        {props.onEditClick && (
          <Button className="btn-edit-poi" onClick={() => props.onEditClick()}>
            <EditIcon />
          </Button>
        )}
        {props.onDeleteClick && (
          <Button
            className="btn-delete-poi"
            onClick={() => props.onDeleteClick()}
          >
            <DeleteIcon />
          </Button>
        )}
        {props.onAddClick && (
          <Button
            className="btn-add-poi-to-template"
            onClick={() => props.onAddClick()}
          >
            <PlusIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Poi;
