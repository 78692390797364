import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { fetchAllLoyalties, fetchGainPointsRules, fetchRedeemPointsRules } from '../../../redux/actions/managePlans/managePlansActions';
import { Col, Container, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import useManagePlansConent from '../customHooks/useManagePlansConent';
import PlanItemList from '../sharedComponents/PlanItemList';
import TabHeaderInfo from '../sharedComponents/TabHeaderInfo';

const INITIAL_LOYALTY_ITEM = {
  title: '',
  description: '',
  state: {
    id: "",
    stateText: ''
  },
  gainPointsRules: [],
  redeemPointsRules: []
}

const LoyaltiesContent = ({
  getAllLoyalties,
  loyalties,
  loading,
  savedPlan,
  deletedPlan,
  planStates,
  getGainPointsRules,
  getRedeemPointsRules,
  gainPointsRulesData,
  redeemPointsRulesData
}) => {

  const {
    onToggle, 
    onItemClick, 
    handleChange, 
    onItemDelete, 
    openConfirmationDialog, 
    onCancelDelete,
    confirmDialogOpen,
    selectedItem,
    modal,
    canEdit,
    editMode,
    itemToDelete,
  } = useManagePlansConent({
    planType: "loyalty",
    planSelectedItem: INITIAL_LOYALTY_ITEM,
    planStates: planStates,
  })

  useEffect(() => {
    getAllLoyalties();
    getGainPointsRules();
    getRedeemPointsRules();
  }, [])

  useEffect(() => {
    if(savedPlan || deletedPlan) {
      getAllLoyalties();
      // getGainPointsRules();
      // getRedeemPointsRules();
    }
  }, [savedPlan, deletedPlan])

  return (
    <Container className="dashboard pois_dashboard">
      <Row>
        <Col>
          <TabHeaderInfo
            title="List of Loyalties"
            description="In this section you can edit/delete saved Loyalties or create new ones."
          />

          {confirmDialogOpen && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={onItemDelete}
              onCancel={onCancelDelete}
              focusCancelBtn
            >
              <p>You are going to delete <b>{itemToDelete?.title ? itemToDelete.title : itemToDelete?.id}</b> item.</p>
              <p>You will not be able to undo this action!</p>
            </SweetAlert>
          )}
          <PlanItemList
            data={loyalties}
            actionType="Loyalty"
            modal={modal}
            onToggle={onToggle}
            selectedItem={selectedItem}
            handleChangeEvent={handleChange}
            onItemClick={onItemClick}
            onItemDelete={openConfirmationDialog}
            canEdit={canEdit}
            saved={savedPlan}
            loading={loading}
            btn="New Loyalty"
            xl={3}
            lg={4}
            md={6}
            sm={12}
          />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    loyalties: state.plans.loyaltiesData,
    loading: state.plans.fetching,
    savedPlan: state.plans.saved,
    deletedPlan: state.plans.deleted,
    planStates: state.plans.planStates,
    gainPointsRulesData: state.plans.gainPointsRulesData,
    redeemPointsRulesData: state.plans.redeemPointsRulesData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getAllLoyalties: (payload) => dispatch(fetchAllLoyalties(payload)),
      getGainPointsRules: (payload) => dispatch(fetchGainPointsRules(payload)),
      getRedeemPointsRules: (payload) => dispatch(fetchRedeemPointsRules(payload))
  };
};

export default React.memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoyaltiesContent)));
