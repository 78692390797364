import React from 'react';
import { MenuItem, FormControl, Select } from '@material-ui/core';

// CUSTOM SCSS
import './SelectField.scss';

const MultipleSelectField = props => {
  return (
    <div
      className={`select_container d-flex align-items-center ${props.placeholder &&
        'full_width'} ${props.className}`}
    >
      {props.label && <div className="select_label">{props.label}</div>}
      <div className="select_field">
        <FormControl className="select_form" variant="outlined">
          {props.placeholder && (
            <div className="select_placeholder">{props.placeholder} *</div>
          )}
          <Select
            disabled={!props.editable}
            value={props.value}
            name={props.name}
            onChange={event => props.onChange(event)}
            displayEmpty
            multiple={props.multiple}
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            }}
          >
            {props.options.map(item => {
              return (
                <MenuItem key={props.id + '-' + item.id} value={item.id}>
                  {item.cost && item.points ? `${item.cost}€ gain ${item.points} points` 
                  : `${item.points} points give ${item.discount}€ discount`
                  }
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
export default MultipleSelectField;
