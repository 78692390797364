import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';

// CUSTOM SCSS
import './Home.scss';

const HomeImg = `${process.env.PUBLIC_URL}/img/home.svg`;

class Home extends PureComponent {
  render() {
    return (
      <Container className="home d-flex flex-column">
        <h3 className="page-title">Welcome to Viarota Dashboard!</h3>
        <Row className="home_img_container align-items-center">
          <img className="home_img" src={HomeImg} alt="dashboard home" />
        </Row>
      </Container>
    );
  }
}

export default withRouter(Home);
