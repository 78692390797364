import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars';

// REDUX
import { connect } from 'react-redux';
import {
  deletTourTemplate,
  getTourTemplates,
  saveTourTemplate,
  setError
} from '../../redux/actions/tourTemplates/tourTemplatesActions';

// CUSTOM COMPONENTS
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import Tour from '../../components/Tour/Tour';
import NewTour from '../../components/NewTour/NewTour';
import Modal from '../../components/Modal/Modal';

// CUSTOM SCSS
import './TourTemplates.scss';

class TourTemplates extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPoisPanel: false,
      showTemplateDetails: false,
      modal: false,
      open: false
    };

    this.openNewTourModal = this.openNewTourModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onClosePoisPanel = this.onClosePoisPanel.bind(this);
    this.addPoiToTemplate = this.addPoiToTemplate.bind(this);
    // this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    // Loads initial data
    console.log('inside componentDidMount()');
    this.props.getTourTemplates();
  }

  componentDidUpdate(prevProps) {
    console.log('inside componentDidUpdate()');
    const { deleted } = this.props.tourTemplates;
    // const { newTemplateId } = this.props.tourTemplates;

    // if (newTemplateId) {
    //   //if new template is saved
    //   console.log('redirecting to new template...', newTemplateId);
    //   this.props.history.push(`/tourTemplates/${newTemplateId}`);
    // }
 
    if(deleted !== prevProps.tourTemplates.deleted && deleted) {
      this.props.getTourTemplates();
    }
  }

  onRemoveItem(item, e) {
    console.log('going to remove item with id: ', item.id);
    //TODO dispatch to action
    this.props.removeFromTemplate(item);
  }

  onBackToList() {
    this.setState({
      showPoisPanel: false
    });
  }

  onMovePoiDown = (poi, e) => {
    let { template } = this.props.tourTemplates;
    let waypointList = template.waypointList;

    let index = waypointList.findIndex(item => item.id === poi.id);
    console.log('found index: ' + index);

    let temp = waypointList[index];
    waypointList[index] = waypointList[index + 1];
    waypointList[index + 1] = temp;

    this.props.updateTour(template);
  };

  onMovePoiUp = (poi, e) => {
    let { template } = this.props.tourTemplates;
    let waypointList = template.waypointList;

    let index = waypointList.findIndex(item => item.id === poi.id);
    console.log('found index: ' + index);

    let temp = waypointList[index];
    waypointList[index] = waypointList[index - 1];
    waypointList[index - 1] = temp;

    this.props.updateTour(template);
  };

  openNewTourModal() {
    this.setState({
      title: '',
      description: ''
    });

    this.toggle();
  }

  closeModal = () => {
    console.log('going to close this modal');
    this.setState({ modal: false });
  };

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  addPoiToTemplate() {
    console.log('add poi to template called in parent');
  }

  saveTourTemplate = tour => {
    this.toggle();
    this.props.saveTourTemplate(tour);
  };

  onClosePoisPanel() {
    this.setState({
      showPoisPanel: false
    });
  }

  /**
   * Loads all data from Redux/API
   */
  loadApiData = () => {};

  /**
   * Updates a pair of filters in Redux
   * @param {*} key
   * @param {*} value
   */
  updateFilter = (key, value) => {
    if (this.state.showReset === false) {
      this.setState({ showReset: true });
    }
    this.props.setFilter({ key, value });
  };

  /**
   * Resets all filters in Redux
   */
  resetFilters = () => {
    this.setState({ showReset: false });
    this.props.resetFilters();
  };

  clearError = () => {
    this.props.setError('');
  };

  render() {
    return (
      <Container className="dashboard tours_dashboard">
        <Row>
          <Col>
            <div className="page_title_container">
              <h3 className="page_title">Suggested Tours</h3>
              <h4 className="page_subtitle">
                In this section you can edit saved tours or create new ones.
              </h4>
            </div>

            <Modal
              isOpen={this.state.modal}
              color="success"
              title="Create New Tour"
              header
              btn="New Tour"
              className="grey"
              toggle={this.toggle}
            >
              <NewTour
                handleSubmit={this.saveTourTemplate}
                toggle={this.toggle}
              />
            </Modal>

            <Row className="tours_container">
              {!this.props.tourTemplates.saved && (
                <Loader
                  loading={this.props.tourTemplates.fetching}
                  className="full_page"
                />
              )}
              <Scrollbars
                renderTrackHorizontal={props => (
                  <div {...props} className="track-horizontal" />
                )}
              >
                <Col>
                  <Row>
                    {this.props.tourTemplates.data.map(tour => {
                      return (
                        <Col md={4} xl={2} lg={3} s={6} xs={12} key={tour.id}>
                          <Tour tour={tour} className="table" onDeleteTemplateTour={() => this.props.deletTourTemplate(tour.id)} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Scrollbars>
            </Row>
          </Col>
        </Row>
        <Error
          error={this.props.tourTemplates.error}
          page={true}
          onErrorClose={() => this.clearError()}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    tourTemplates: state.tourTemplates,
    title: state.title
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTourTemplates: () => dispatch(getTourTemplates()),
    saveTourTemplate: payload => dispatch(saveTourTemplate(payload)),
    setError: payload => dispatch(setError(payload)),
    deletTourTemplate: payload => dispatch(deletTourTemplate(payload))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TourTemplates)
);
