import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';

// CUSTOM COMPONENTS
import Loader from '../Loader/Loader';
import Poi from '../Poi/Poi';
import NewPOI from '../NewPOI/NewPOI';
import Modal from '../Modal/Modal';
import FilterInput from '../FilterInput/FilterInput';
import SelectField from '../SelectField/SelectField';

// CUSTOM DATA
import { CATEGORIES } from '../../shared/utils/utils';

// CUSTOM SCSS
import './PoisList.scss';

const PAGE_SIZE = 50;

const PoisList = props => {
  const [pois, setPois] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [initialPois, setInitialPois] = useState([]);
  const [page, setPage] = useState(0);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [disabledScroll, setDisabledScroll] = useState(false);

  //add the "All" category to the existing ones
  const categories = [{ value: '', label: 'Όλες' }, ...CATEGORIES];

  useEffect(() => {
    setSearchTerm('');
    setInitialPois(props.pois.data);
    setPois(props.pois.data.slice(0, 50));
    setDisabledScroll(props.pois.data.length < PAGE_SIZE);
    setPreviousScrollTop(0);
    setPage(1);
  }, [props.pois]);

  /**
   * Clears search term
   *
   */
  const initializePois = () => {
    setSearchTerm('');
    setInitialPois(props.pois.data);
    setPois(props.pois.data.slice(0, 50));
    setDisabledScroll(props.pois.data.length < PAGE_SIZE);
    setPreviousScrollTop(0);
    setPage(1);
  };

  /**
   * Loads more pois upon scroll
   *
   */
  const getMorePois = event => {
    if (event.scrollTop % 200 === 0) {
      if (disabledScroll) {
        return;
      }
      setDisabledScroll(true);
      // Check if scroll down
      if (event.scrollTop >= previousScrollTop) {
        const allPois = initialPois;
        const nextPois = allPois.slice(
          page * PAGE_SIZE,
          (page + 1) * PAGE_SIZE
        );
        const newPois = pois.concat(nextPois);
        if (nextPois.length < PAGE_SIZE) {
          setDisabledScroll(true);
        } else {
          setDisabledScroll(false);
        }
        setPage(page + 1);
        setPois(newPois);
        setPreviousScrollTop(event.scrollTop);
      }
    }
  };

  /**
   * Filters initial pois by search term
   *
   */
  const filterPois = term => {
    if (term === searchTerm) {
      return;
    }

    setSearchTerm(term);
    setPois([]);
    if (term !== '') {
      const filteredPois = props.pois.data.filter(
        item => item.name.toLowerCase().indexOf(term.toLowerCase()) >= 0
      );
      setInitialPois(filteredPois);
      setPois(filteredPois.slice(0, 50));
      setDisabledScroll(filteredPois.length < PAGE_SIZE);
      setPreviousScrollTop(0);
      setPage(0);
    } else {
      initializePois();
    }
  };

  return (
    <>
      <div className="filters_container d-flex justify-content-between">
        <Modal
          isOpen={props.modal}
          color="success"
          title="Create New POI"
          header
          btn={props.btn}
          className="grey"
          toggle={() => props.toggle()}
        >
          <NewPOI
            toggle={() => props.toggle()}
            poi={props.selectedPoi}
            handleChange={event => props.handleChange(event)}
            savePoi={poi => props.savePoi(poi)}
            editable={props.editable}
            loader={props.pois.fetching}
          />
        </Modal>
        <div className="d-flex justify-content-end align-items-start">
          <SelectField
            options={categories}
            value={props.selectedCategory || ''}
            name="type"
            id="categories"
            placeholder="Filter pois by category"
            onChange={event => props.handleFilterByCategory(event)}
            editable={true}
            className="no_bg"
          />
          <FilterInput
            onSubmit={term => filterPois(term)}
            onClear={() => initializePois()}
          />
        </div>
      </div>
      <Row className="pois_container">
        {!props.pois.saved && (
          <Loader loading={props.pois.fetching} className="full_page" />
        )}
        <Scrollbars
          onScrollFrame={event => getMorePois(event)}
          renderTrackHorizontal={props => (
            <div {...props} className="track-horizontal" />
          )}
        >
          <Col>
            <Row>
              {pois.map(poi => {
                return (
                  <Col
                    xl={props.xl}
                    lg={props.lg}
                    md={props.md}
                    s={props.s}
                    key={poi.id}
                  >
                    <Poi
                      poi={poi}
                      onEditClick={
                        props.onItemClick
                          ? () => props.onItemClick(poi, true)
                          : null
                      }
                      onDeleteClick={
                        props.onItemDelete
                          ? () => props.onItemDelete(poi)
                          : null
                      }
                      onViewClick={
                        props.onViewClick ? () => props.onViewClick(poi) : null
                      }
                      onAddClick={
                        props.onAddClick ? () => props.onAddClick(poi) : null
                      }
                      editable={true}
                    ></Poi>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Scrollbars>
      </Row>
    </>
  );
};

export default PoisList;
