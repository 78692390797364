import DeleteIcon from 'mdi-react/DeleteIcon'
import EditIcon from 'mdi-react/EditIcon'
import React from 'react'
import { Button } from 'reactstrap'
import './User.scss'

const User = (props) => {
  const renderRole = (role) => {
    if(role === "") {
      return "TRAVELER"
    } 
    return role.toString().toUpperCase()
  }


  return (
    <div className="user_container d-flex align-items-center">
      <div className="user">
        <div className="user_username">
          <span className="text_ellipsis">{props.user.username}</span>
        </div>
        <div className="user_email">
          <span className="text_ellipsis">{props.user.email}</span>
        </div>
        {props.user.role !== null && <div className="user_role">
          <span className="text_ellipsis">{renderRole(props.user.role)}</span>
        </div>}
      </div>
      <div className="vertical_buttons d-flex flex-column justify-content-center">
        {props.onEditClick && (
          <Button
            disabled={props.user.username === props.currentLoggedUser.username && props.user.email === props.currentLoggedUser.email}
            className="btn-edit-user" 
            onClick={() => props.onEditClick()}>
            <EditIcon />
          </Button>
        )}
        {props.onDeleteClick && (
          <Button
            disabled={props.user.username === props.currentLoggedUser.username && props.user.email === props.currentLoggedUser.email}
            className="btn-delete-user"
            onClick={() => props.onDeleteClick()}
          >
            <DeleteIcon />
          </Button>
        )}
      </div>
    </div>
  )
}

export default User
