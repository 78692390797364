import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';

// CUSTOM COMPONENTS
import Loader from '../Loader/Loader';
import Modal from '../Modal/Modal';
import FilterInput from '../FilterInput/FilterInput';
import SelectField from '../SelectField/SelectField';

// CUSTOM DATA
import { CATEGORIES } from '../../shared/utils/utils';

// CUSTOM SCSS
import '../PoisList/PoisList.scss';
import NewBusinessPOI from '../NewBussinesPOI/NewBusinessPOI';
import { FormGroup, Grid, Switch, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BusinessPoi from '../BusinessPoi/BusinessPoi';

const PAGE_SIZE = 50;

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#eb5b41',
        borderColor: '#eb5b41',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const BusinessPoisList = props => {
  const [pois, setPois] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [initialPois, setInitialPois] = useState([]);
  const [page, setPage] = useState(0);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [disabledScroll, setDisabledScroll] = useState(false);
  const [onlySubscribed, setOnlySubscribed] = React.useState(props.showSubOnly)

  const handleChange = (event) => {
    setOnlySubscribed(event.target.checked);
    props.showActiveBusiness(event.target.checked);
  };

  //add the "All" category to the existing ones
  const categories = [{ value: '', label: 'Όλες' }, ...CATEGORIES];

  useEffect(() => {
    setSearchTerm('');
    setInitialPois(props.pois.businessData);
    setPois(props.pois.businessData.slice(0, 50));
    setDisabledScroll(props.pois.businessData.length < PAGE_SIZE);
    setPreviousScrollTop(0);
    setPage(1);
  }, [props.pois]);

  /**
   * Clears search term
   *
   */
  const initializePois = () => {
    setSearchTerm('');
    setInitialPois(props.pois.businessData);
    setPois(props.pois.businessData.slice(0, 50));
    setDisabledScroll(props.pois.businessData.length < PAGE_SIZE);
    setPreviousScrollTop(0);
    setPage(1);
  };

  /**
   * Loads more pois upon scroll
   *
   */
  const getMorePois = event => {
    if (event.scrollTop % 200 === 0) {
      if (disabledScroll) {
        return;
      }
      setDisabledScroll(true);
      // Check if scroll down
      if (event.scrollTop >= previousScrollTop) {
        const allPois = initialPois;
        const nextPois = allPois.slice(
          page * PAGE_SIZE,
          (page + 1) * PAGE_SIZE
        );
        const newPois = pois.concat(nextPois);
        if (nextPois.length < PAGE_SIZE) {
          setDisabledScroll(true);
        } else {
          setDisabledScroll(false);
        }
        setPage(page + 1);
        setPois(newPois);
        setPreviousScrollTop(event.scrollTop);
      }
    }
  }; 

  return (
    <>
      <div className="filters_container d-flex justify-content-between">
        <Modal
          isOpen={props.modal}
          color="success"
          title={props.selectedPoi.id ? "Edit Business" : "Create New Business"}
          header
          btn={props.btn}
          className="grey"
          toggle={() => props.toggle()}
        >
          <NewBusinessPOI
            toggle={() => props.toggle()}
            poi={props.selectedPoi}
            subscriptions={props.pois.subscriptions}
            loyalties={props.pois.loyalties}
            handleChange={event => props.handleChange(event)}
            savePoi={poi => props.savePoi(poi)}
            editable={props.editable}
          />
        </Modal>
        <div className="d-flex justify-content-end align-items-start">
          <FormGroup className='d-flex flex-row align-items-center'>
          <Typography component="p" className='business-poi-active-prompt' style={{marginRight: "8px"}}>
            Show only Subscribed Businesses
          </Typography>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <AntSwitch checked={onlySubscribed} onChange={handleChange} name="sunOnlyBusinesses" />
                </Grid>
              </Grid>
            </Typography>
          </FormGroup>
        </div>
      </div>
      <Row className="pois_container">
        {!props.pois.saved && (
          <Loader loading={props.pois.fetching} className="full_page" />
        )}
        <Scrollbars
          onScrollFrame={event => getMorePois(event)}
          renderTrackHorizontal={props => (
            <div {...props} className="track-horizontal" />
          )}
        >
          <Col>
            <Row>
              {pois.map(poi => {
                return (
                  <Col
                    xl={props.xl}
                    lg={props.lg}
                    md={props.md}
                    s={props.s}
                    key={poi.id}
                  >
                    <BusinessPoi
                      poi={poi}
                      onEditClick={
                        props.onItemClick
                          ? () => props.onItemClick(poi, true)
                          : null
                      }
                      onDeleteClick={
                        props.onItemDelete
                          ? () => props.onItemDelete(poi)
                          : null
                      }
                      onViewClick={
                        props.onViewClick ? () => props.onViewClick(poi) : null
                      }
                      onAddClick={
                        props.onAddClick ? () => props.onAddClick(poi) : null
                      }
                      editable={true}
                    ></BusinessPoi>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Scrollbars>
      </Row>
    </>
  );
};

export default BusinessPoisList;
