import React, { useEffect } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import TabHeaderInfo from '../sharedComponents/TabHeaderInfo'
import { Col, Container, Row } from 'reactstrap'
import useManagePlansConent from '../customHooks/useManagePlansConent'
import { fetchGainPointsRules, fetchRedeemPointsRules } from '../../../redux/actions/managePlans/managePlansActions';
import SweetAlert from 'react-bootstrap-sweetalert';
import PlanItemList from '../sharedComponents/PlanItemList';

const INITIAL_GAIN_POINTS_ITEM = {
  cost: 0,
  points: 0
}

const GainPointsContent = ({
  getGainPointsRules,
  gainPointsRulesData,
  savedPlan,
  deletedPlan,
  loading
}) => {
  const {
    onToggle, 
    onItemClick, 
    handleChange, 
    onItemDelete, 
    openConfirmationDialog, 
    onCancelDelete,
    confirmDialogOpen,
    selectedItem,
    modal,
    canEdit,
    editMode,
    itemToDelete,
  } = useManagePlansConent({
    planType: "gainPoints",
    planSelectedItem: INITIAL_GAIN_POINTS_ITEM,
  })

  useEffect(() => {
    getGainPointsRules({loading: true});
  }, [])

  useEffect(() => {
    if(savedPlan || deletedPlan) {
      console.log('called')
      getGainPointsRules({loading: true});
    }
  }, [savedPlan, deletedPlan])

  return (
    <Container className="dashboard pois_dashboard">
      <Row>
        <Col>
          <TabHeaderInfo
            title="List of Gain Points Rules"
            description="In this section you can edit/delete saved Gain Points Rules or create new ones."
          />

          {confirmDialogOpen && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={onItemDelete}
              onCancel={onCancelDelete}
              focusCancelBtn
            >
              <p>You are going to delete <b>{itemToDelete?.title ? itemToDelete.title : itemToDelete?.id}</b> item.</p>
              <p>You will not be able to undo this action!</p>
            </SweetAlert>
          )}
          <PlanItemList
            data={gainPointsRulesData}
            actionType="GainPoints"
            modal={modal}
            onToggle={onToggle}
            selectedItem={selectedItem}
            handleChangeEvent={handleChange}
            onItemClick={onItemClick}
            onItemDelete={openConfirmationDialog}
            canEdit={canEdit}
            saved={savedPlan}
            loading={loading}
            btn="New Gain Points Rule"
            xl={3}
            lg={4}
            md={6}
            sm={12}
          />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.plans.fetching,
    savedPlan: state.plans.saved,
    deletedPlan: state.plans.deleted,
    planStates: state.plans.planStates,
    gainPointsRulesData: state.plans.gainPointsRulesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGainPointsRules: (payload) => dispatch(fetchGainPointsRules(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GainPointsContent)) 
