import React, { useState } from 'react'
import { withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import { clearSubscriptions, saveSubscriptionsToDB, updateSubscriptionsToDB } from '../../../redux/actions/managePlans/managePlansActions';
import InputField from '../../InputField/InputField';
import SelectField from '../../SelectField/SelectField';

const SubscriptionsForm = (props) => {
  // ------------ VARIABLES ------------ //
  const [formFieldsErrors, setFormFieldsErrors] = useState({});

  // variable that store a hook to access the redux dispatch function
  const dispatch = useDispatch();

  // ------------ PROPS ------------ //
  const {canEdit, item, handleChangeEvent, planStates, subscriptionTypes, toggle} = props;

  // ------------ FUNCTIONS ------------ //

  /**
   * Function that handles cost field change event
   * @param {*} e 
   */
  const handleCostChangeEvent = (e) => {
      handleChangeEvent(e);
      setFormFieldsErrors((prevState) => {
        let tempError = Object.assign({}, prevState.formFieldsErrors); // creating copy of state variable
        tempError['cost'] = validateCost(parseInt(e.target.value, 10));
        return tempError
      })
  }

  /**
   * Function that validates cost field according to conditional rules
   * @param {*} costValue 
   * @returns cost error "costError" variable that is text error to show in UI
   */
  const validateCost = (costValue) => {
    let costError = '';
    if (parseInt(costValue, 10) < 0) {
      costError = 'Cost field value must be 0 or greater';
    } else {
      costError = undefined;
    }
    console.log('costError', costError, 'costValue', costValue)
    return costError;
  };

  /**
   * Function that makes basic validation for form fields
   * @returns errors object
   */
  const validateFormFields = () => {
    const errors = {};
    if (validateCost(item.cost) !== undefined) {
      errors.cost = validateCost(item.cost);
    }
    return errors;
  }

  /**
   * Function that given subscriptionType calculates starting and endingDates for subscriptions according to subscriptionType (montly/yearly)
   * @param {*} subType subscriptionType object
   * @returns object of "startingDate" and "endingDate"
   */
  const calculateDates = (subType) => {
    let startingDate;
    let endingDate;
    const currentDate = new Date();

    // Calculate the end date based on the subscription type
    const endDate = new Date(currentDate);

    if (subType.title === 'monthly') {
      // Add 1 month to the current date
      endDate.setMonth(currentDate.getMonth() + 1);
    } else if (subType.title === 'yearly') {
      // Add 1 year to the current date
      endDate.setFullYear(currentDate.getFullYear() + 1);
    }

    // Set the starting date to the current date
    startingDate = currentDate.toISOString();

    // Set the end date in the desired format
    endingDate = endDate.toISOString();
    
    return {startingDate, endingDate};
  };

  /**
   * Function that handles the form submit action
   * @param {*} e 
   */
  const handleSubscriptionSubmit = (e) => {
    e.preventDefault();
    const errors = validateFormFields();
    setFormFieldsErrors(errors);
    console.log("Form Error Validations: ", errors);

    // if errors object has no errors inside it continues normal submit functionality
    if(Object.keys(errors).length === 0) {
      let sDate, eDate;
      if(!item.startingDate && !item.endingDate) {
        const {startingDate, endingDate} = calculateDates(item.subscriptionType)
        sDate = startingDate
        eDate = endingDate
      } else {
        sDate = item.startingDate
        eDate = item.endingDate
      }
      
      const basePayload = {
        title: item.title,
        stateId: item.state.id,
        subscriptionTypeId: item.subscriptionType.id,
        cost: item.cost,
        startingDate: sDate,
        endingDate: eDate
      }
      
      dispatch(clearSubscriptions());
      if(canEdit) {
        dispatch(saveSubscriptionsToDB(basePayload));
      } else {
        // create new object editedPayload spreading basePayload and adds id property to it
        const editedPayload = { ...basePayload, id: item.id}

        /*
          destructure editedPayload object to get the remaining of editedPayload as "newEditedPayload" to use it in UPDATE request 
          for subscriptions
         */
        // const {startingDate, endingDate, subscriptionTypeId, ...newEditedPayload} = editedPayload;
        dispatch(updateSubscriptionsToDB(editedPayload))
      }
      toggle();
    }
  }

  return (
    <form
        className="form new_poi_form"
        onSubmit={(event) => handleSubscriptionSubmit(event)}
      >
        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              type="text"
              name="title"
              placeholder="Enter Subscription Title"
              label="Title"
              texture="material"
              value={item.title ||''}
              required
              onChange={event => handleChangeEvent(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              type="number"
              name="cost"
              placeholder="Enter Subscription Cost"
              label="Cost"
              texture="material"
              value={`${item.cost}`|| "0"}
              required
              textError={formFieldsErrors?.cost}
              onChange={event => handleCostChangeEvent(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <SelectField
              options={subscriptionTypes}
              value={item.subscriptionType.id}
              name="subscriptionType"
              id="subscriptionType"
              placeholder="Select a Subscription Type"
              onChange={(event) => {
                handleChangeEvent(event)
              }}
              editable={canEdit}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <SelectField
              options={planStates}
              value={item.state.id}
              name="state"
              id="state"
              placeholder="Select a Subscription State"
              onChange={(event) => {
                handleChangeEvent(event)
              }}
              editable={true}
            />
          </div>
        </div>

        <ButtonToolbar className="form__button-toolbar d-flex justify-content-end">
            <Button type="button" onClick={() => toggle()}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              {canEdit ? 'Add' : 'Save'}
            </Button>
          </ButtonToolbar>
    </form>
  )
}

const mapStateToProps = (state) => {
    return {
      planStates: state.plans.planStates,
      subscriptionTypes: state.plans.subscriptionTypes
    };
  };

export default withRouter(connect(mapStateToProps, {})(SubscriptionsForm))
