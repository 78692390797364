import React from 'react'

const TabHeaderInfo = ({title, description}) => {
  return (
    <div className="page_title_container">
        <h3 className="page_title">{title}</h3>
        <h4 className="page_subtitle">{description}</h4>
    </div>
  )
}

export default TabHeaderInfo
