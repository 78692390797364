import { combineReducers, legacy_createStore as createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
/** import ViaRota Reducers  */
import {
  sidebarReducer,
  themeReducer,
  customizerReducer,
  rtlReducer,
  authReducer,
  poisReducer,
  tourTemplatesReducer,
  statisticsReducer,
  managePlansReducer
} from './redux/reducers/index';
import usersReducer from './redux/reducers/users/usersReducer';

const appReducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  sidebar: sidebarReducer,
  customizer: customizerReducer,
  user: authReducer,
  pois: poisReducer,
  tourTemplates: tourTemplatesReducer,
  statistics: statisticsReducer,
  usersManage: usersReducer,
  plans: managePlansReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
