import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { deleteGainPointsRuleFromDB, deleteLoyaltyFromDB, deleteRedeemPointsRuleFromDB, deleteSubscriptionFromDB, saveLoyaltiesToDB } from '../../../redux/actions/managePlans/managePlansActions';

const useManagePlansConent = ({
    planSelectedItem,
    planStates,
    subscriptionTypes,
    planType
}) => {

    // variable that store a hook to access the redux dispatch function
    const dispatch = useDispatch();

    // ------------ VARIABLES ------------ //
    const [modal, setModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(planSelectedItem);
    const [editMode, setEditMode] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

  // ------------ FUNCTIONS ------------ //

    /**
     * Function that handles item click - we use it when we click edit icon on item in lists
     * @param {*} item item object that we click to edit
     * @param {*} canUserEdit onEdit mode we pass this as false
     */
    const onItemClick = (item, canUserEdit) => {
        console.log('item', item, "canuserEdit", canUserEdit)
        setSelectedItem(item);
        setCanEdit(canUserEdit);
        setEditMode(true);
        setModal(true);
      };

   /**
     * Toggles plan item modal form
     */
  const onToggle = () => {
    if(modal) {
      setModal(prevState => !prevState)
      setSelectedItem(planSelectedItem)
      setEditMode(false);
      setCanEdit(true)
    } else {
      setModal(prevState => !prevState)
    }
  };

   /**
   * Delete Subscription item
   */
   const onItemDelete = () => {
    // deleteSubscrtiption(userToDelete);
    if(planType === 'loyalty') {
      console.log('going to delete loyalty: ', itemToDelete);
      dispatch(deleteLoyaltyFromDB(itemToDelete));
    } else if(planType === 'subscription') {
      console.log('going to delete subscription: ', itemToDelete);
      dispatch(deleteSubscriptionFromDB(itemToDelete));
    } else if(planType === 'gainPoints') {
      console.log('going to delete gain points rule: ', itemToDelete);
      dispatch(deleteGainPointsRuleFromDB(itemToDelete));
    } else if(planType === 'redeemPoints') {
      console.log('going to delete redeem points rule: ', itemToDelete);
      dispatch(deleteRedeemPointsRuleFromDB(itemToDelete))
    } else {}
    setConfirmDialogOpen(false);
  }

  /**
   * Open Confirmation delete dialog
   */
  const openConfirmationDialog = (item) => {
    setItemToDelete(item);
    setConfirmDialogOpen(true);
  };

  /**
   * Cancel delete action and close confirmation delete dialog
   */
  const onCancelDelete = () => {
    setConfirmDialogOpen(false);
  };

  /**
   * Handles changes from Subscriptions form
   * @param {*} event
   */
  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    console.log('value', value);
    console.log('name', name);

    setSelectedItem((prevState) => {
      let tempSubscription = Object.assign({}, prevState); // creating copy of state variable
      if(name === "subscriptionType") {
        const mySubValue = subscriptionTypes?.filter(item => item.id === value)
        tempSubscription[name] = {
          id: value,
          title: mySubValue[0]?.title
        }
      } else if(name === "state") {
        const myStateValue = planStates?.filter(item => item.id === value)
        tempSubscription[name] = {
          id: value,
          stateText: myStateValue[0]?.stateText
        }
      } else if(name === "points") {
        tempSubscription[name] = parseInt(value, 10);
      }  else if(name === "cost") {
        tempSubscription[name] = parseInt(value, 10);
      } else if(name === "discount") {
        tempSubscription[name] = parseInt(value, 10);
      } else if(name === "redeemPointsRules") {
        tempSubscription[name] = value
      } else if(name === "gainPointsRules") {
        tempSubscription[name] = [value]
      } else {
        tempSubscription[name] = value;
      }
    
      return tempSubscription;
    });
  };


  return {
    onItemClick,
    onToggle,
    handleChange,
    onItemDelete,
    openConfirmationDialog,
    onCancelDelete,
    modal,
    selectedItem,
    editMode,
    canEdit,
    confirmDialogOpen,
    itemToDelete
  }
}

export default useManagePlansConent
