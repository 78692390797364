import React from 'react'
import { withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import { clearLoyalties, saveLoyaltiesToDB, updateLoyaltiesToDB } from '../../../redux/actions/managePlans/managePlansActions';
import MultipleSelectField from '../../SelectField/MultipleSelectField';
import InputField from '../../InputField/InputField';
import SelectField from '../../SelectField/SelectField';

const LoyaltiesForm = (props) => {

  // variable that store a hook to access the redux dispatch function
  const dispatch = useDispatch();

  // ------------ PROPS ------------ //
  const {canEdit, item, handleChangeEvent, toggle, planStates, redeemPointsRulesData, gainPointsRulesData} = props;

// ------------ FUNCTIONS ------------ //

/**
 * Function that handles title field change
 * @param {*} e 
 */
  const handleTitleChange = (e) => {
    handleChangeEvent(e);
  }

  /**
   * Function that handles description field change
   * @param {*} e 
   */
  const handleDescriptionChange = (e) => {
    handleChangeEvent(e);
  }

  /**
   * Function that modifies data for our payload (save/edit)
   * @param {*} data 
   * @returns the modified data 
   */
  const modifyRulesData = (data) => {
    let modifiedData = [];
    if(data.length > 0 && data[0].id) {
      data.map(item => {modifiedData.push(item.id)})
      return modifiedData;
    }
    return data;
  }

  /**
   * Function that handles Loyalty form submit
   * @param {*} e 
   */
  const handleLoyaltiesSubmit = (e) => {
    e.preventDefault();
    const myFinalPayload = {
      description: item.description,
      title: item.title,
      stateId: item.state.id,
      redeemPointsRulesIds: modifyRulesData(item.redeemPointsRules),
      gainPointsRulesIds: modifyRulesData(item.gainPointsRules)
    }
    dispatch(clearLoyalties());
    if(canEdit) {
      dispatch(saveLoyaltiesToDB(myFinalPayload));
    } else {
      const editedPayload = {...myFinalPayload, id: item.id}
      dispatch(updateLoyaltiesToDB(editedPayload))
    }
    toggle();
  }

/**
 * Function that sorts gainPointsRules from lower cost to higher cost
 */
  const sortedGainRulesData = gainPointsRulesData.sort((a, b) =>{
    // in case of equal value return 1st the item with lower points
    if(a.cost===b.cost) {
      return a.points - b.points
    }
    return a.cost - b.cost
  });

  /**
   * Function that sorts redeemPointsRules from lower points to higher points
   */
  const sortedRedeemRulesData = redeemPointsRulesData.sort((a, b) => {
    // in case of equal value return 1st the item with lower discount
    if(a.points === b.points) {
      return a.discount - b.discount
    }
    return a.points - b.points
  });

  return (
    <form
      className="form new_poi_form"
      onSubmit={(event) => handleLoyaltiesSubmit(event)}
    >
      <div className="form__form-group">
        <div className="form__form-group-field">
          <InputField
            // readOnly={!canEdit}
            type="text"
            name="title"
            placeholder="Enter Subscription Title"
            label="Title"
            texture="material"
            value={item.title ||''}
            required
          //   textError={this.state.formFieldsErrors?.username}
            onChange={event => handleTitleChange(event)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <div className="form__form-group-field">
          <InputField
            // readOnly={!canEdit}
            type="text"
            name="description"
            placeholder="Enter Subscription Title"
            label="Description"
            texture="material"
            value={item.description ||''}
            required
          //   textError={this.state.formFieldsErrors?.username}
            onChange={event => handleDescriptionChange(event)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <div className="form__form-group-field">
          <MultipleSelectField
            options={sortedGainRulesData}
            value={modifyRulesData(item.gainPointsRules)}
            name="gainPointsRules"
            id="gainPointsRules"
            placeholder="Select Gain Points Rules"
            onChange={(event) => {
              handleChangeEvent(event)
            }}
            editable={true}
            multiple={false}
          />
        </div>
      </div>

      <div className="form__form-group">
        <div className="form__form-group-field">
          <MultipleSelectField
            options={sortedRedeemRulesData}
            value={modifyRulesData(item.redeemPointsRules)}
            name="redeemPointsRules"
            id="redeemPointsRules"
            placeholder="Select Redeem Points Rules"
            onChange={(event) => {
              handleChangeEvent(event)
            }}
            editable={true}
            multiple={true}
          />
        </div>
      </div>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <SelectField
            options={planStates}
            value={item.state.id}
            name="state"
            id="state"
            placeholder="Select a Loyalty state"
            onChange={(event) => {
              handleChangeEvent(event)
            }}
            editable={true}
          />
        </div>
      </div>

      <ButtonToolbar className="form__button-toolbar d-flex justify-content-end">
        <Button type="button" onClick={() => toggle()}>
          Cancel
        </Button>
        <Button color="success" type="submit">
          {canEdit ? 'Add' : 'Save'}
        </Button>
        </ButtonToolbar>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    planStates: state.plans.planStates,
    gainPointsRulesData: state.plans.gainPointsRulesData,
    redeemPointsRulesData: state.plans.redeemPointsRulesData
  };
};

export default withRouter(connect(mapStateToProps, {})(LoyaltiesForm))
