import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import { Box, Paper } from '@material-ui/core';

// REDUX
import { connect } from 'react-redux';

import SweetAlert from 'react-bootstrap-sweetalert';

// CUSTOM COMPONENTS
import CustomTabs from '../../components/ManagePlans/CustomTabs';
import SubscriptionsContent from '../../components/ManagePlans/Subscriptions/SubscriptionsContent';
import LoyaltiesContent from '../../components/ManagePlans/Loyalties/LoyaltiesContent';
import GainPointsContent from '../../components/ManagePlans/GainPoints/GainPointsContent';
import RedeemPointsContent from '../../components/ManagePlans/RedeemPoints/RedeemPointsContent';

// CUSTOM SCSS
import "../Pois/Pois.scss"
import './ManagePlans.scss'
import { fetchPlanStates } from '../../redux/actions/managePlans/managePlansActions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            <div className="tab-panel-container">{children}</div>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const tabsData = [
    {
        label: "Subscriptions"
    },
    {
        label: "Loyalties"
    },
    {
        label: "Gain Points Rules"
    },
    {
        label: "Redeem Points Rules"
    }
  ]

class ManagePlans extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      title: '',
      editable: true,
      editMode: false,
      tabValue: 0,
    };
  }

  componentDidMount() {
    this.props.getPlanStates();
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  /**
   * Handle changes in tabs layout
   * @param {*} event event object from onChange action
   * @param {*} newValue the newvalue that tab selection returns
   */
  handleTabChange = (event, newValue) => {
    this.setState({tabValue: newValue});
  }

  render() {
    return (
      <Container>
            <Paper square>
                <CustomTabs
                    tabsData={tabsData}
                    value={this.state.tabValue}
                    onChange={this.handleTabChange}
                    ariaLabel="manage plans tabs"
                ></CustomTabs>
            </Paper>
            <TabPanel value={this.state.tabValue} index={0}>
                <SubscriptionsContent />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
                <LoyaltiesContent />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={2}>
                <GainPointsContent />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={3}>
                <RedeemPointsContent />
            </TabPanel>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlanStates: (payload) => dispatch(fetchPlanStates(payload))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagePlans));