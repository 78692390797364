import React from 'react'
import Modal from '../../Modal/Modal'
import PlanItemForm from './PlanItemForm';
import { Col, Row } from 'reactstrap';
import PlanListItem from './PlanListItem';
import Scrollbars from 'react-custom-scrollbars';
import './PlanItemList.scss'
import Loader from '../../Loader/Loader'

const PlanItemList = (props) => {
  const {modal, actionType, data, selectedItem, loading, handleChangeEvent, onItemClick, onToggle, onItemDelete, canEdit, saved, btn, xl, lg, md, sm} = props;
  return (
    <>
      <div className="filters_container d-flex justify-content-between">
        <Modal
          isOpen={modal}
          color="success"
          title={selectedItem?.id ? `Edit ${actionType}` : `Create New ${actionType}`}
          header
          btn={btn}
          className="grey"
          toggle={() => onToggle()}
          >
          <PlanItemForm
            toggle={() => onToggle()}
            item={selectedItem}
            actionType={actionType}
            handleChangeEvent={handleChangeEvent}
            canEdit={canEdit}
          />
        </Modal>
      </div>
      <Row className="plan_list_container">
        <Loader loading={loading} className="full_page" />
        <Scrollbars
        //   onScrollFrame={event => getMorePois(event)}
          renderTrackHorizontal={props => (
            <div {...props} className="track-horizontal" />
          )}
        >
          <Col>
            <Row>
              {(data || [])?.map(item =>(
                  <Col
                    xl={xl}
                    lg={lg}
                    md={md}
                    s={sm}
                    key={item.id}
                  >
                    <PlanListItem
                      actionType={actionType}
                      item={item}
                      onEditClick={
                        onItemClick
                          ? () => onItemClick(item, false)
                          : null
                      }
                      onDeleteClick={
                        onItemDelete
                          ? () => onItemDelete(item)
                          : null
                      }
                    />
                  </Col>) 
                )}
            </Row>
          </Col>
        </Scrollbars>
      </Row>
    </>
  )
}

export default PlanItemList
