import React, { useEffect, useState } from 'react'
import TabHeaderInfo from '../sharedComponents/TabHeaderInfo'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { fetchAllSubscriptions, fetchPlanStates, fetchSubscriptionTypes } from '../../../redux/actions/managePlans/managePlansActions';
import PlanItemList from '../sharedComponents/PlanItemList';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Col, Container, Row } from 'reactstrap';
import useManagePlansConent from '../customHooks/useManagePlansConent';

const INITIAL_SUBS_ITEM = {
  title: '',
  cost: 0,
  state: {
    id: "",
    stateText: ''
  },
  subscriptionType: {
    id: "",
    title: ''
  },
}

const SubscriptionsContent = ({
    getAllSubscriptions,
    subscriptions,
    getSubscriptionTypes,
    subscriptionTypes,
    planStates,
    savedPlan,
    loading,
    deletedPlan
}) => {

  const {
    onToggle, 
    onItemClick, 
    handleChange, 
    onItemDelete, 
    openConfirmationDialog, 
    onCancelDelete,
    confirmDialogOpen,
    selectedItem,
    modal,
    canEdit,
    editMode,
    itemToDelete,
  } = useManagePlansConent({
    planType: "subscription",
    planSelectedItem: INITIAL_SUBS_ITEM,
    planStates: planStates,
    subscriptionTypes: subscriptionTypes
  })

    useEffect(() => {
        getAllSubscriptions();
        getSubscriptionTypes();
    },[])

    useEffect(() => {
      if(savedPlan || deletedPlan) {
        getAllSubscriptions();
        // getSubscriptionTypes();
      }
    }, [savedPlan, deletedPlan])

  return (
    <Container className="dashboard pois_dashboard">
      <Row>
        <Col>
          <TabHeaderInfo
            title="List of Subscriptions"
            description="In this section you can edit/delete saved Subscriptions or create new ones."
          />

          {confirmDialogOpen && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={onItemDelete}
              onCancel={onCancelDelete}
              focusCancelBtn
            > <p>You are going to delete <b>{itemToDelete?.title ? itemToDelete.title : itemToDelete?.id}</b> item.</p>
              <p>You will not be able to undo this action!</p>
            </SweetAlert>
          )}

          <PlanItemList
            data={subscriptions}
            actionType="Subscription"
            modal={modal}
            onToggle={onToggle}
            selectedItem={selectedItem}
            handleChangeEvent={handleChange}
            onItemClick={onItemClick}
            onItemDelete={openConfirmationDialog}
            canEdit={canEdit}
            saved={savedPlan}
            loading={loading}
            btn="New Subscription"
            xl={3}
            lg={4}
            md={6}
            s={12}
          />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
    return {
      subscriptions: state.plans.subscriptionsData,
      savedPlan: state.plans.saved,
      deletedPlan: state.plans.deleted,
      loading: state.plans.fetching,
      subscriptionTypes: state.plans.subscriptionTypes,
      planStates: state.plans.planStates,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getAllSubscriptions: (payload) => dispatch(fetchAllSubscriptions(payload)),
        getSubscriptionTypes: (payload) => dispatch(fetchSubscriptionTypes(payload))
    };
  };

export default React.memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionsContent)));
