import React, { Component } from 'react'
import InputField from '../InputField/InputField'
import { Button, ButtonToolbar } from 'reactstrap'
import '../NewPOI/NewPOI.scss'
import { ROLE_TYPE } from '../../shared/utils/utils'
import SelectField from '../SelectField/SelectField'
import { Autocomplete } from '@material-ui/lab'
// import { TextField } from '@material-ui/core'
import dataApi from '../../services/data/dataApi'
import { CircularProgress, FormHelperText, TextField, makeStyles } from '@material-ui/core'
import './NewUser.scss';
import {decrypt, encrypt, generateRandomPassword } from '../../shared/utils/helpers'
import { VALID_EMAIL_REGEX, VALID_USERNAME_REGEX } from '../../services/validationFields/validationFields'

class NewUser extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         poisError: '',
         searchTerm: '',
         responsedPois: [],
         poisArray: this.props.user.poiIds || [],
         selectedPois:  [],
         mixedPois: [],
         searchLoading: false,
         formFieldsErrors: {}
      }
      this.searchTimeout = null;
      // this.secretKey = process.env.REACT_APP_SECRET_KEY
    }

    componentDidMount() {
      this.fillSelectedPois();
    }

    componentDidUpdate(prevProps, prevState) {
    }
    
    /**
     * Function that returns list of poi/pois matching the search "whole word" term
     * @param {*} term search term
     */
    getPoisSearchText = async(term) => {
      try {
        const response = await dataApi.fetch('filterPois', term);
        if (response.status === 200) {
          console.log('inside Action:', response);
          if (response.data.length > 0) {
            this.setState({responsedPois: response.data})
            this.setState({mixedPois: [...response.data, ...this.state.selectedPois]})
          } else {
            this.setState({poisError: 'No data found for this search term'})
          }
        } else {
          this.setState({poisError: 'Something went wrong. Please try later!'})
        }
      } catch (error) {
        this.setState({poisError: 'Something went wrong. Please try later!'})
      } finally {
        this.setState({searchLoading: false})
      }
    }

    /**
     * Function that make GET request to take the whole poi item given the ID param
     * @param {*} id the id of poi item
     * @returns the response data or null. We send null if something goes wrong for safety and we modify our final data in "fillSelectedPois" funct
     */
    getPoiByID = async(id) => {
      try {
        const response = await dataApi.fetch('poiById', id);
        if(response.status === 200) {
          console.log('inside getPoiByID', response);
          if(response.data) {
            return response.data
          }
        } else {
          return null
        }
      } catch (error) {
        return null
      }
    }

    /**
     * Function that reads whole poisArray of ids and make "getPoiByID" request and return the whole poi object. 
     * Also, pass that data to our state in order Autocompole component that store/show poiIds, get the whole poi data and not only poiIds
     * to show the Name of POIs in Chip.
     */
    fillSelectedPois = async() => {
      if(this.state.poisArray.length > 0) {
        let data = await Promise.all(this.state.poisArray.map(async(item) => {
          return await this.getPoiByID(item);
        }))
        data = data.filter(item => item !== null)

        // we set data to mixedPois and selectedPois and clear poisArray in order autocomplete disable value to be false. We did this in case of 
        // slow request user couldn't interact with the autocomplete field until our action bill be finished. When finished, we clear 
        // poisArray to enable autocomplete field.
        this.setState({mixedPois: data, selectedPois: data, poisArray: []});
      }
    }

  handleSearchPois = (event) => {
    const searchTerm = event.target.value;
    // console.log('myValueTerm', searchTerm);
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.setState({searchLoading: true})
    // Set a new timeout to trigger the search after a delay
    this.searchTimeout = setTimeout(() => {
      
      this.getPoisSearchText(searchTerm);
    }, 1000); // Adjust the debounce delay as needed
  }

  clearFormData = () => {
    this.setState({
          poisError: '',
          searchTerm: '',
          responsedPois: [],
          selectedPois: [],
          mixedPois: [],
          formFieldsErrors: {},
          searchLoading: false,
    });
  }

  validateEmail = (emailValue) => {
    let emailError = '';
    // if(!emailValue) {
    //   emailValue = "test@test.gr"
    // }
    if (!emailValue?.trim()) {
      emailError = 'Email field is required';
    } else if (emailValue?.trim() && !VALID_EMAIL_REGEX.test(emailValue?.trim())) {
      emailError = 'Invalid email address';
    } else {
      emailError = undefined;
    }
    console.log('emailError', emailError, 'emailValue', emailValue)
    return emailError;
  };

  validateUsername = (usernameValue) => {
    let usernameError = '';
    if (!usernameValue?.trim()) {
      usernameError = 'Username field cannot be empty';
    } else if (usernameValue?.trim() && !VALID_USERNAME_REGEX.test(usernameValue.trim())) {
      usernameError = 'Only alphanumerics allowed';
    } else if(usernameValue?.trim()?.length < 6) {
      usernameError = 'Username must have at least 6 characters';
    } else if(usernameValue?.trim()?.length > 15) {
      usernameError = 'Username must have maximum 15 characters';
    }   else {
      usernameError = undefined;
    }
    console.log('usernameError', usernameError, "usernameValue", usernameValue);
    return usernameError;
  };

  validateSelectedPoiIds = (poiIdsValue) => {
    let poiIdsError = '';
    if(poiIdsValue.length < 1) {
      poiIdsError = 'You must select at least on POI'
    } else {
      poiIdsError = undefined
    }
    return poiIdsError;
  }


  handleEmailChange = (event) => {
    // const errors = {};
    this.props.handleChange(event)
      // errors.email = this.validateEmail(event.target.value)
    this.setState((prevState) => {
      let tempError = Object.assign({}, prevState.formFieldsErrors); // creating copy of state variable
      tempError['email'] = this.validateEmail(event.target.value);
      return {
        formFieldsErrors: tempError
      }
    })
  }

  handleUsernameChange = (event) => {
    this.props.handleChange(event)
      // errors.email = this.validateEmail(event.target.value)
    this.setState((prevState) => {
      let tempError = Object.assign({}, prevState.formFieldsErrors); // creating copy of state variable
      tempError['username'] = this.validateUsername(event.target.value);
      return {
        formFieldsErrors: tempError
      }
    })
  }

  handleAutoCompleteChangeValidation = () => {
    this.setState((prevState) => {
      let tempError = Object.assign({}, prevState.formFieldsErrors); // creating copy of state variable
      tempError['poiIds'] = this.validateSelectedPoiIds(this.state.selectedPois);
      return {
        formFieldsErrors: tempError
      }
    })
  }

  handleAutocompleteChange = (event, newValue) => {
    console.log('TRIGGER');
    console.log('newValue', newValue)
    this.setState({
       selectedPois: newValue 
      },
      () => {
        this.handleAutoCompleteChangeValidation()
      }
    );
  };

  validateFormFields = (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value
    const errors = {};

    if(name) {
      if (name === "email" && this.validateEmail(value) !== undefined) {
        errors.email = this.validateEmail(value);
      }
      if (name === "username" && this.validateUsername(value) !== undefined) {
        errors.username = this.validateUsername(value);
      }
      if(name=== "role" && value === "BUSINESS" && this.state.selectedPois.length < 1) {
        errors.poiIds = this.validateSelectedPoiIds(this.state.selectedPois)
      }
      
    } else {
      if (this.validateEmail(this.props.user.email) !== undefined) {
        errors.email = this.validateEmail(this.props.user.email);
      }
      if (this.validateUsername(this.props.user.username) !== undefined) {
        errors.username = this.validateUsername(this.props.user.username);
      }
      if(this.props.user.role === "BUSINESS" && this.state.selectedPois.length < 1) {
        errors.poiIds = this.validateSelectedPoiIds(this.state.selectedPois)
      }
    }
    this.setState({formFieldsErrors: errors})
    return errors;
  }

  saveChanges = (e) => {
    e.preventDefault();
    const errors = this.validateFormFields();
    this.setState({formFieldsErrors: errors})
    console.log("Form Error Validations: ", errors);
    if(Object.keys(errors).length === 0) {
      console.log('this.state.selectedPois', this.state.selectedPois);
      let myPoiIds = [];
      let myPayload = {}; 
      this.state.selectedPois.map(item => {
        myPoiIds.push(item.id);
      })
      
      
      if(this.props.canEdit) {
        const password = generateRandomPassword();
        // console.log('password', password);
        // const encryptedPass = encrypt(password);

        myPayload = {
          id: this.props.user?.id || null,
          username: this.props.user.username,
          email: this.props.user.email,
          role: this.props.user.role || "TRAVELER",
          poiIds: myPoiIds,
          password: password
        }
        this.props.createUser(myPayload)
      } else {
        myPayload = {
          id: this.props.user?.id,
          role: this.props.user.role || "TRAVELER",
          poiIds: myPoiIds,
        }
        this.props.saveUser(myPayload);
      }
      console.log('myPayload', myPayload);
      this.clearFormData();
    }
  }

  render() {
    return (
        <form
        className="form new_poi_form"
        onSubmit={(event) => this.saveChanges(event)}
      >
        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              readOnly={!this.props.canEdit}
              type="text"
              name="username"
              placeholder="Enter a username"
              label="Username"
              texture="material"
              value={this.props.user.username ||''}
              required
              textError={this.state.formFieldsErrors?.username}
              // onChange={(event) => {
              //   this.props.handleChange(event)
              //   this.validateFormFields(event)
              // }}
              onChange={event => this.handleUsernameChange(event)}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              readOnly={!this.props.canEdit}
              type="text"
              name="email"
              placeholder="Enter email"
              label="Email"
              texture="material"
              value={this.props.user.email ||''}
              required
              textError={this.state.formFieldsErrors?.email}
              onChange={(event) => {this.handleEmailChange(event)}}
            />
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <SelectField
              options={ROLE_TYPE}
              value={this.props.user.role ?? ROLE_TYPE[0].value}
              name="role"
              id="userRole"
              placeholder="Select a role"
              onChange={(event) => {
                this.props.handleChange(event)
                // this.validateFormFields(event);
              }}
              editable={true}
            />
          </div>
        </div>
      
        {this.props.user.role === 'BUSINESS' ? <div className="form__form-group">
          <div className="form__form-group-field autocomplete-form-field">
            <Autocomplete
              disabled={this.state.poisArray.length > 0}
              multiple
              id="multiple-autocomplete"
              options={this.state.mixedPois} // Replace with your data source
              getOptionLabel={(option) => option?.name || option} // Replace with the label property of your data
              value={this.state.selectedPois}
              onChange={this.handleAutocompleteChange}
              required
              loading={this.state.searchLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  variant="outlined"
                  label="Select Pois *"
                  onChange={this.handleSearchPois}
                  error={Boolean(this.state.formFieldsErrors?.poiIds)}
                  helperText={
                       this.state.formFieldsErrors?.poiIds ? 
                       "Please select at least one POI": <></>
                     
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.searchLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            {/* Display selected options */}
            {/* <div>
              Selected Options:
              {this.state.selectedPois?.map((option) => (
                <span key={option.name}>{option.name}, </span>
              ))}
            </div> */}
          </div>
        </div>: null}

        <ButtonToolbar className="form__button-toolbar d-flex justify-content-end">
            <Button type="button" onClick={() => this.props.toggle()}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              {this.props.canEdit ? 'Add' : 'Save'}
            </Button>
          </ButtonToolbar>
      </form>
    )
  }
}

export default NewUser;