import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import InputField from '../../InputField/InputField'
import { Button, ButtonToolbar } from 'reactstrap'
import { clearRedeemPoints, saveRedeemPointsRulesToDB, updateRedeemPointsRulesToDB } from '../../../redux/actions/managePlans/managePlansActions';


const RedeemPointsForm = (props) => {

  // ------------ VARIABLES ------------ //
  const [formFieldsErrors, setFormFieldsErrors] = useState({});

  // variable that store a hook to access the redux dispatch function
  const dispatch = useDispatch();
  // ------------ PROPS ------------ //
  const {canEdit, item, handleChangeEvent, planStates, subscriptionTypes, toggle} = props;

  // ------------ FUNCTIONS ------------ //

  /**
  * Function that handles cost field change event
  * @param {*} e 
  */
  const handleDiscountChangeEvent = (e) => {
    handleChangeEvent(e);
    setFormFieldsErrors((prevState) => {
      let tempError = Object.assign({}, prevState); // creating copy of state variable
      console.log('tempError', tempError);
      tempError['cost'] = validateDiscount(parseInt(e.target.value, 10));
      return tempError
    })
  }

  /**
  * Function that handles cost field change event
  * @param {*} e 
  */
  const handlePointsChangeEvent = (e) => {
    handleChangeEvent(e);
    setFormFieldsErrors((prevState) => {
      let tempError = Object.assign({}, prevState); // creating copy of state variable
      console.log('tempError', tempError);
      tempError['points'] = validatePoints(parseInt(e.target.value, 10));
      return tempError
    })
  }

  /**
  * Function that validates discount field according to conditional rules
  * @param {*} discountValue 
  * @returns discount error "discountError" variable that is text error to show in UI
  */
  const validateDiscount = (discountValue) => {
    let discountError = '';
    if (parseInt(discountValue, 10) === 0) {
      discountError = 'Cost field value must be greater than 0';
    } else {
      discountError = undefined;
    }
    console.log('discountError', discountError, 'discountValue', discountValue)
    return discountError;
  };

  /**
  * Function that validates points field according to conditional rules
  * @param {*} pointsValue 
  * @returns points error "pointsError" variable that is text error to show in UI
  */
  const validatePoints = (pointsValue) => {
    let pointsError = '';
    if (parseInt(pointsValue, 10) === 0) {
      pointsError = 'Points field value must be greater than 0';
    } else {
      pointsError = undefined;
    }
    console.log('pointsError', pointsError, 'pointsValue', pointsValue)
    return pointsError;
  };

  /**
   * Function that makes basic validation for form fields
   * @returns errors object
   */
  const validateFormFields = () => {
    const errors = {};
    if (validateDiscount(item.discount) !== undefined) {
      errors.discount = validateDiscount(item.discount);
    }
    if(validatePoints(item.points) !== undefined) {
      errors.points= validatePoints(item.points);
    }
    return errors;
  }

  const handleRedeemPointsRulesSubmit = (e) => {
    e.preventDefault();
    const errors = validateFormFields();
    setFormFieldsErrors(errors);
    console.log("Form Error Validations: ", errors);

    // if errors object has no errors inside it continues normal submit functionality
    if(Object.keys(errors).length === 0) {
      const basePayload = {
        points: item.points,
        discount: item.discount
      }
      dispatch(clearRedeemPoints())
      if(canEdit) {
        dispatch(saveRedeemPointsRulesToDB(basePayload))
      } else {
        const editedPayload = {...basePayload, id: item.id}
        dispatch(updateRedeemPointsRulesToDB(editedPayload))
      }
      toggle();
    }
  }

  return (
    <form
      className="form new_poi_form"
      onSubmit={(event) => handleRedeemPointsRulesSubmit(event)}
    >
      <div className="form__form-group">
        <div className="form__form-group-field">
          <InputField
            type="number"
            name="discount"
            placeholder="Enter Redeem Rule Discount"
            label="Discount"
            texture="material"
            value={`${item.discount}`|| "0"}
            required
            textError={formFieldsErrors?.discount}
            onChange={event => handleDiscountChangeEvent(event)}
          />
        </div>
      </div>

      <div className="form__form-group">
        <div className="form__form-group-field">
          <InputField
            type="number"
            name="points"
            placeholder="Enter Redeem Rule Points"
            label="Points"
            texture="material"
            value={`${item.points}`|| "0"}
            required
            textError={formFieldsErrors?.points}
            onChange={event => handlePointsChangeEvent(event)}
          />
        </div>
      </div>

      <ButtonToolbar className="form__button-toolbar d-flex justify-content-end">
        <Button type="button" onClick={() => toggle()}>
          Cancel
        </Button>
        <Button color="success" type="submit">
          {canEdit ? 'Add' : 'Save'}
        </Button>
      </ButtonToolbar>
    </form>
  )
}

export default RedeemPointsForm
