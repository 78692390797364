import authenticationApi from "../../../services/authentication/authenticationApi";
import dataApi from "../../../services/data/dataApi";

export const FETCH_USERS = "FETCH_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
export const CLEAR_USERS = "CLEAR_USERS";
export const SAVE_USER_SUCCESSFUL = "SAVE_USER_SUCCESSFUL";
export const DELETE_USER_SUCCESSFUL = "DELETE_USER_SUCCESSFUL";
export const CREATE_NEW_USER_SUCCESSFUL = "CREATE_NEW_USER_SUCCESSFUL";

const GENERIC_API_ERROR = 'Something went wrong. Please try later!';

const setUsers = payload => {
    return {
        type: FETCH_USERS,
        payload
    }
}

export const clearUsers = payload => {
    return {
        type: CLEAR_USERS,
        payload
    }
}

const usersLoading = (payload) => {
    console.log('calling USERS_LOADING', payload);
    return {
        type: USERS_LOADING,
        payload
    }
}

const setUsersError = (payload) => {
    return {
        type: FETCH_USERS_ERROR,
        payload
    }
}

const saveUser = (payload) => {
    console.log('calling saveUser', payload);
    return {
      type: SAVE_USER_SUCCESSFUL,
      payload,
    };
  }

  const deleteUser = () => {
    console.log('calling deleteUser');
    return {
      type: DELETE_USER_SUCCESSFUL,
    };
  }

  const createNewUser = () => {
    console.log('calling createNewUser');
    return {
      type: CREATE_NEW_USER_SUCCESSFUL,
    }
  }

export const getAllUsers = (payload) => {
    return async (dispatch) => {
        dispatch(usersLoading())
        try {
            const params = {...payload}
            const response = await dataApi.fetch('users', params);
            console.log('responseUsers', response.data);
           if(response && response.status === 200) {
            dispatch(setUsers(response.data));
           } else {
            dispatch(setUsersError(GENERIC_API_ERROR))
           }
        } catch (error) {
            dispatch(setUsersError(GENERIC_API_ERROR))
        }
    }
}

export function saveUserToDB(payload) {
    console.log('calling saveUserToDB...');
  
    return async (dispatch) => {
      try {
        dispatch(usersLoading())
        let response;
        console.log('UserPayload', payload);
        if (payload.update) {
          response = await dataApi.update('users', payload.user);
        }
        if (response.status === 200) {
          console.log('inside Action:', response);
          let action = saveUser(response.data);
          console.log('action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setUsersError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setUsersError('Something went wrong. Please try later!'));
      }
    };
  }

  export function deleteUserFromDB(payload) {
    console.log('calling deleteUserFromDB...');
  
    return async (dispatch) => {
      try {
        dispatch(usersLoading())
        console.log('mydeletePayload', payload);
        const response = await dataApi.remove('users', payload);
        console.log('response', response);
        if (response.status === 200) {
          console.log('deleteUserFromDB response:', response);
          let action = deleteUser();
          console.log('deleteUserFromDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setUsersError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setUsersError('Something went wrong. Please try later!'));
      }
    };
  }



export function createUserFromAdmin(payload) {
  console.log("calling createUserFromAdmin");
  return async dispatch => {
    console.log('REGISTER+PAYLOAD+USER', payload);
    try {
      dispatch(usersLoading())
      const response = await authenticationApi.registerManagedUser({user: payload.user, sendEmail: true});
      if (response && response.status === 200) {
        console.log('User to Create', payload.actionType);
        dispatch(createNewUser());
      } else {
        if (response.data.message === 'USERNAME_EXISTS') {
          dispatch(
            setUsersError(
              'Your username is already taken. Please choose another one!'
            )
          );
        } else if (response.data.message === 'EMAIL_EXISTS') {
          dispatch(
            setUsersError(
              'Your email is already taken. Please choose another one!'
            )
          );
        } else {
          dispatch(setUsersError('Something went wrong. Please try later!'));
        }
      }
    } catch (error) {
      dispatch(setUsersError('Something went wrong. Please try later!'));
    }
  };
}