import CryptoJS from "crypto-js";

const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777'
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd'
};

const secretKey = process.env.REACT_APP_SECRET_KEY

export const themes = {
  ligthTheme,
  darkTheme
};

function getTooltipStyles(themeName, type) {
  switch (themeName) {
    case 'theme-dark': {
      const { backgroundColor, color } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color }
      };
    }
    case 'theme-light': {
      return ligthTheme;
    }
    default:
      return ligthTheme;
  }
}

export const generateRandomPassword = () => {
  const length = 8; // Desired password length
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; // Characters to choose from
  let newPassword = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    newPassword += charset.charAt(randomIndex);
  }

  return newPassword;
};

export const encrypt = (text) => {
  const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
  return ciphertext;
}

// Function to decrypt data
export const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export default getTooltipStyles;
