import { CLEAR_USERS, CREATE_NEW_USER_SUCCESSFUL, DELETE_USER_SUCCESSFUL, FETCH_USERS, FETCH_USERS_ERROR, SAVE_USER_SUCCESSFUL, USERS_LOADING } from "../../actions/users/usersActions";

const initialState = {
    usersData: [],
    loading: false,
    error: '',
    saved: false,
    deleted: false,
    savedUser: {}
  };


  export default function(state = initialState, action) {
    switch (action.type) {
      case FETCH_USERS:
        return {
          ...state,
          usersData: action.payload,
          error: '',
          saved: false,
          deleted: false
        };
      case USERS_LOADING: {
        return {
            ...state,
            error: '',
            loading: action.payload,
            saved: false,
            deleted: false
        }
      }
      case FETCH_USERS_ERROR: {
        return {
            ...state,
            error: action.payload,
            saved: false
        }
      }
      case SAVE_USER_SUCCESSFUL: {
        return {
          ...state,
          error: '',
          loading: false,
          saved: true,
          savedUser: action.payload
        };
      }

      case DELETE_USER_SUCCESSFUL: {
        return {
          ...state,
          error: '',
          loading: false,
          deleted: true
        };
      }

      case CREATE_NEW_USER_SUCCESSFUL: {
        return {
          ...state,
          error: '',
          loading: false,
          saved: true
        }
      }

      case CLEAR_USERS: {
        return {
            ...state,
            error: '',
            loading: false,
            usersData: [],
            deleted: false,
            saved: false
        }
      }
      default:
        return state;
    }
  }