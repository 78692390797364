const LOCALE = 'el';

const CATEGORIES = [
  {
    value: 'beach',
    label: 'Ήλιος - Θάλασσα'
  },
  {
    value: 'culture',
    label: 'Ιστορία - Πολιτισμός'
  },
  {
    value: 'church',
    label: 'Θρησκευτικός Τουρισμός'
  },
  {
    value: 'nature',
    label: 'Περιηγήσεις - Ευεξία'
  },
  {
    value: 'gastronomy',
    label: 'Γαστρονομία'
  }
];

const CATEGORΥ_NAMES = {
  beach: 'Ήλιος - Θάλασσα',
  culture: 'Ιστορία - Πολιτισμός',
  church: 'Θρησκευτικός Τουρισμός',
  nature: 'Περιηγήσεις - Ευεξία',
  gastronomy: 'Γαστρονομία'
};

const TRANSPORTATION = [
  {
    value: 'DRIVING',
    label: 'Driving'
  },
  {
    value: 'WALKING',
    label: 'Walking'
  }
];

const STATUS = [
  {
    value: false,
    label: 'Active'
  },
  {
    value: true,
    label: 'Inactive'
  }
];

const DURATION = [
  {
    value: 'week',
    label: 'Week'
  },
  {
    value: 'month',
    label: 'Month'
  },
  {
    value: 'year',
    label: 'Year'
  }
];

const INITIAL_POI = {
  name: '',
  description: '',
  address: '',
  photoURL: '',
  longitude: 23.7081520298828,
  latitude: 37.9743470423469,
  type: 'culture',
  visitTimes: 0,
  location: {
    type: 'Point',
    coordinates: [23.7081520298828, 37.9743470423469]
  },
  proposedDuration: 0,
  __v: 0,
  nameTranslations: {
    en: '',
    el: ''
  },
  descriptionTranslations: {
    en: '',
    el: ''
  },
  isBusiness: false
};

const ROLE_TYPE = [
  {
    value: "TRAVELER",
    label: "TRAVELER"
  }, 
  {
    value: "BUSINESS",
    label: "BUSINESS",
  },
  {
    value: "ADMIN",
    label: "ADMIN"
  }, 
]

const INITIAL_USER_FORM_FIELDS = {
  username: "",
  email: "",
  role: "TRAVELER",
  poiIds: [],
}

export {
  LOCALE,
  CATEGORIES,
  CATEGORΥ_NAMES,
  TRANSPORTATION,
  STATUS,
  DURATION,
  INITIAL_POI,
  INITIAL_USER_FORM_FIELDS,
  ROLE_TYPE,
};
