import React from 'react';

// CUSTOM SCSS
import './Terms.scss';

const Terms = () => (
  <div className="page__wrapper">
    <p>
      <strong>Viarota Terms of Use</strong>
    </p>
    <p>
      <strong>PREAMBLE</strong>
    </p>
    <p>
      <strong>Viarota</strong> is a product that has been designed by
      by the Municipality of Avdera (MoA) and has been developed by Athens
      Technology Center SA (ATC).
    </p>
    <p>
      Viarota is a mobile application for the promotion
      of tourist and cultural values in the Municipality of Avdera, through
      personalized tours.
    </p>
    <p>
      Responsible for the operation of Viarota and counterparty to these
      Terms of Use is the Municipality of Avdera (&ldquo;
      <strong>MoA</strong>&rdquo;).
    </p>
    <p>
      By accessing and using Viarota, you are \"accepting\" and agreeing
      to be bound by these Terms of Use (&ldquo;<strong>Terms of Use</strong>
      &rdquo; or &ldquo;<strong>Agreement</strong>&rdquo;) to the exclusion of
      all other terms. The simple use by you of Viarota constitutes your
      acceptance of the Agreement and the terms, conditions, and notices
      contained therein. These terms should be read in conjunction with the
      Viarota Privacy Policy.
    </p>
    <p>
      Wherever used in these Terms of Use, &ldquo;<strong>you</strong>&rdquo;,
      &ldquo;<strong>your</strong>&rdquo;, &ldquo;<strong>User</strong>&rdquo;,
      or similar terms, means the person or legal entity accessing or using the
      Platform. &nbsp;If you are accessing and using the Platform on behalf of a
      company (such as your employer) or other legal entity, you represent and
      warrant that you have the authority to bind that company or other legal
      entity to these Terms of Use.
    </p>
    <p>
      <strong>OTHER DEFINITIONS</strong>
    </p>
    <p>
      <strong>&ldquo;Viarota&rdquo;</strong> means the commercially available
      platform from ATC (viarota.com), which has been used for the development
      of the Viarota Service.
    </p>
    <p>
      <strong>&nbsp;&ldquo;Supported Platform(s)&rdquo;</strong>&nbsp;means the
      external service and content providers currently supported by the Service,
      including Google and Viarota.
    </p>
    <p>
      <strong>&ldquo;Third-Party Services</strong>&rdquo; means products,
      services, applications, or websites made available by third parties
      through the Service.
    </p>
    <p>
      &ldquo;<strong>Software</strong>&rdquo; means the online software
      applications provided by ATC as part of Viarota.
    </p>
    <p>
      <strong>MODIFICATION OF THESE TERMS OF USE</strong>
    </p>
    <p>
      MoA reserves the right to change the terms, conditions, and notices under
      which Viarota is offered, including but not limited to any charges
      associated with the use of Viarota.
    </p>
    <p>
      <strong>LINKS TO THIRD PARTY SITES AND THIRD-PARTY SERVICES</strong>
    </p>
    <p>
      Viarota may contain links to other Web Sites (&ldquo;Linked
      Sites&rdquo;). The Linked Sites are not under the control of MoA and MoA
      is not responsible for the contents of any Linked Site, including without
      limitation any link contained in a Linked Site, or any changes or updates
      to a Linked Site. MoA is not responsible for webcasting or any other form
      of transmission received from any Linked Site. MoA is providing these
      links to you only as a convenience, and the inclusion of any link does not
      imply endorsement by MoA of the site or any association with its
      operators.
    </p>
    <p>
      Viarota may contain links to or allow you to connect and use certain
      external third-party services, in conjunction with your use of our
      Service. Certain features of our Service may depend on the availability of
      these Third-Party Services and the features and functionality they make
      available to us. We do not control Third-Party Service features and
      functionalities, and they may change without any notice to us. If any
      Third-Party Service stops providing access to some or all of the features
      or functionality currently or historically available to us, or stops
      providing access to such features and functionality on reasonable terms,
      as determined by MoA in our sole discretion, we may stop providing access
      to certain features and functionalities of Viarota. We will not be
      liable to you for any refunds or any damage or loss arising from or in
      connection with any such change made by the Third-Party Service or any
      resulting change to our Service. You irrevocably waive any claim against
      MoA with respect to such Third-Party Services.
    </p>
    <p>
      <strong>REGISTRATION, USER ACCOUNT AND USER DATA</strong>
    </p>
    <p>
      You agree and understand that you are responsible for maintaining the
      confidentiality of passwords associated with any account you use to access
      Viarota. For security reasons, we use a two-factor authentication
      process for logging in to the Viarota Service.
    </p>
    <p>
      For your registration at Viarota we use and store your email
      provided by you.
    </p>
    <p>
      For more details on the use and storage of these data, please see our
      Privacy Policy, which is available here and incorporated into these Terms.
    </p>
    <p>
      <strong>AVAILABILITY OF SERVICE</strong>
    </p>
    <p>
      While we will use commercially reasonable efforts to keep our Service
      available and accessible, Viarota may be unavailable from time to
      time for repairs, upgrades, routine and emergency maintenance, or other
      interruptions that that may be out of our reasonable control, including
      any outages of Supported Platforms or any related application programming
      interfaces (&ldquo;APIs&rdquo;) and integrations. Interruptions of our
      Service shall not serve as a basis to demand any full or partial refunds.
    </p>
    <p>
      The provision of the Service is subject to the availability of the
      Supported Platforms API services. Any change applied by these Supported
      platforms may impose changes to the level of service provide by
      Viarota. We do not control features and functionality of the
      Supported platforms and they may change without any notice to us. If any
      Supported platform stops providing access to some or all of the features
      or functionality currently or historically available to us, or stops
      providing access to such features and functionality on reasonable terms,
      as determined by ATC in our sole discretion, we may stop providing access
      to certain features and functionalities of Viarota. We will not be
      liable to you for any refunds or any damage or loss arising from or in
      connection with any such change made by ant Supported platform or any
      resulting change to the Service. You irrevocably waive any claim against
      MoA with respect to such Supported Platforms.
    </p>
    <p>
      We reserve the right to modify features and functionalities of
      Viarota from time to time in our sole discretion. We will determine
      in our sole discretion whether any new features require additional fees.
      We may decide to add new features to the Service and make them generally
      available at no cost to users. We may or may not provide notice to you of
      changes to the Service. We will not be liable to you or to any third party
      for any modifications, price increases, or discontinuations of
      Viarota.
    </p>
    <p>
      <strong>NO UNLAWFUL OR PROHIBITED USE</strong>
    </p>
    <p>As a condition of your use of the Service, you warrant to MoA that:</p>
    <p>
      You shall not use Viarota for any purpose that is unlawful or
      prohibited by these terms, conditions, and notices.
    </p>
    <p>
      You shall not use Viarota in any manner which could damage, disable,
      overburden, or impair the Viarota Service or interfere with any
      other party's use and enjoyment of Viarota.
    </p>
    <p>
      You may not obtain or attempt to obtain any materials or information
      through any means not intentionally made available or provided for through
      Viarota.
    </p>
    <p>
      <strong>PROPER USE OF Viarota</strong>
    </p>
    <p>
      You agree that by using Viarota, you will not (the following list is
      presented as example and is without limitation);
    </p>
    <ul>
      <li>
        Defame, abuse, harass, stalk, threaten others or otherwise violate the
        legal rights (such as rights of privacy and publicity) of others.
      </li>
      <li>
        Publish, post, upload, distribute or disseminate any inappropriate,
        profane, defamatory, infringing, obscene, indecent or unlawful topic,
        name, material or information.
      </li>
      <li>
        Upload files that contain software or other material protected by
        intellectual property laws (or by rights of privacy of publicity) or
        provide links to sites where other users can obtain unauthorised
        downloads, unless you own or control the rights thereto or have received
        all necessary consents.
      </li>
      <li>
        Upload files that contain malware, corrupted files, or any other similar
        software or programs that may damage the operation of the Service or of
        another's computer.
      </li>
      <li>
        Download any file posted by another user of Viarota that you know,
        or reasonably should know, cannot be legally distributed in such manner.
      </li>
      <li>
        Falsify or delete any author attributions, legal or other proper notices
        or proprietary designations or labels of the origin or source of
        software or other material contained in a file that is uploaded.
      </li>
      <li>
        Restrict or inhibit any other user from using and enjoying
        Viarota.
      </li>
      <li>
        Violate any code of conduct or other guidelines which may be applicable.
      </li>
      <li>
        Harvest or otherwise collect information about others, including e-mail
        addresses, without their consent.
      </li>
      <li>
        Conduct any research or analysis that isolates a small group of
        individuals or any single individual for unlawful or discriminatory
        purposes.
      </li>
      <li>
        Use Viarota to target, segment, or profile any individual based on
        health, negative financial status or condition, political affiliation or
        beliefs, racial or ethnic origin, religious or philosophical affiliation
        or beliefs, sex life or sexual orientation, trade union membership, data
        relating to any alleged or actual commission of a crime, or any other
        sensitive categories of personal information prohibited by law.
      </li>
      <li>
        Attempt to copy, modify, duplicate, create derivative works from, frame,
        mirror, republish, download, display, transmit, or distribute all or any
        portion of the Software in any form or media or by any means.
      </li>
      <li>
        Attempt to reverse compile, disassemble, reverse engineer or otherwise
        reduce to human-perceivable form all or any part of the Software.
      </li>
      <li>
        Access all or any part of the Services in order to build a product or
        service, which competes with Viarota.
      </li>
      <li>Violate any applicable laws or regulations.</li>
    </ul>
    <p>
      You agree that you are responsible for your own use of Viarota, for
      any posts, comments, or text messages you make, for any content you
      collect, process and share via the Service, and for any consequences
      thereof. You are therefore solely responsible and liable for all activity
      conducted through your account in connection with the Platform.
    </p>
    <p>
      MoA has no obligation to monitor Viarota and does not control or
      endorse the content, messages or information found in the Viarota
      Service. As such, MoA specifically disclaims all liability with regard to
      the Viarota Service and to any actions resulting from your
      participation in and/or use of the Viarota Service.
    </p>
    <p>
      Notwithstanding the foregoing, MoA reserves the right to terminate your
      access to Viarota, if you violate the letter or spirit of this
      Agreement, abuse the Services offered via Viarota, or otherwise
      create risk or possible legal exposure to MoA.
    </p>
    <p>
      <strong>WARRANTY DISCLAIMER</strong>
    </p>
    <p>
      Except as expressly provided herein, to the greatest extent permitted by
      applicable law, MoA expressly excludes and disclaims all warranties of any
      kind, whether express, implied, statutory, or otherwise. Without limiting
      the generality of the foregoing, MoA specifically disclaims all express or
      implied warranties of design, merchantability, fitness for a particular
      purpose, title, quality, and non-infringement, that its services will meet
      your requirements, or that its services will always be available,
      accessible, uninterrupted, timely, secure, accurate, complete, or
      error-free. No advice or information, whether oral or written, obtained
      from the Viarota Service or elsewhere will create any warranty or
      condition not expressly stated in this agreement. Except as expressly
      provided herein, the services are provided on an &ldquo;as is&rdquo; and
      &ldquo;as available&rdquo; basis. These limitations shall apply
      notwithstanding the failure of essential purpose of any limited remedy
      provided herein.
    </p>
    <p>
      MoA disclaims any and all responsibility or liability in relation to the
      content made available through the Service, including any content or
      services provided by third parties. MoA does not control or vet user
      content and is not responsible for what users post, transmit, or share on
      or through the Service. ATC is not responsible or liable in any manner for
      any third-party services or supported platforms associated with or
      utilized in connection with the Service, including the failure of any such
      third-party services or supported platforms. ATC expressly denies any
      responsibility resulting from hacking, tampering, or other unauthorized
      access or use of the Platform or your account or the information contained
      therein. If you are dissatisfied or harmed by Viarotaor anything
      related to Viarota, you may cancel your account and terminate this
      agreement (as described below in the section &ldquo;Termination/Access
      restriction&rdquo;) and such termination shall be your sole and exclusive
      remedy (and MoA&rsquo;s sole and exclusive liability).
    </p>
    <p>
      <strong>LIABILITY DISCLAIMER</strong>
    </p>
    <p>
      To the maximum extent permitted by applicable law, in no event shall MoA,
      its directors, employees, agents, licensors&nbsp; or its suppliers be
      liable for any direct, indirect, punitive, incidental, special,
      consequential damages or any damages whatsoever including, without
      limitation, damages for loss of use, data, profits, or other intangible
      losses, arising out of or in any way connected with the use or performance
      of the Viarota Service, with the delay or inability to use
      Viarota or the related services, or with the provision of or failure
      to provide the Services.
    </p>
    <p>
      Because some states/jurisdictions do not allow the exclusion or limitation
      of liability for consequential or incidental damages, the above limitation
      may not apply to you. If you are dissatisfied with any portion of the
      Viarota Service, or with any of these terms of use, your sole and
      exclusive remedy is to discontinue using the Viarota Service.
    </p>
    <p>
      <strong>COPYRIGHT NOTICES</strong>
    </p>
    <p>
      Except as expressly set forth in this Agreement, this Agreement does not
      grant either Party any rights, implied or otherwise, to the other&rsquo;s
      content or any of the other&rsquo;s intellectual property.
    </p>
    <p>
      MoA retains all intellectual property rights in its respective services,
      content and Software.
    </p>
    <p>
      <strong>TRADEMARKS</strong>
    </p>
    <p>
      The names of actual companies and products mentioned herein may be the
      trademarks of their respective owners.
    </p>
    <p>
      Except as expressly set forth in this Agreement, this Agreement does not
      grant either Party any rights, implied or otherwise, to the other&rsquo;s
      content or any of the other&rsquo;s trademarks.
    </p>
    <p>
      <strong>INDEMNIFICATION</strong>
    </p>
    <p>
      You agree to hold harmless and indemnify MoA, and any of the parties
      involved in the Viarota Service, or their subsidiaries, affiliates,
      officers, agents, and employees from and against any third-party claim
      arising from or in any way related to: (a) Your use of Viarota, (b)
      Your breach or alleged breach of any of the terms, restrictions,
      obligations or representations under this Agreement, including any
      liability or expense arising from all claims, losses, damages (actual and
      consequential), suits, judgments, litigation costs and attorneys&rsquo;
      fees, of every kind and nature. In such a case, MoA or any party involved
      in the Viarota Service will provide you with written notice of such
      claim, suit or action.
    </p>
    <p>
      <strong>GENERAL&nbsp;TERMS</strong>
    </p>
    <p>
      MoA&rsquo;s performance of this Agreement is subject to existing laws and
      legal process, and nothing contained in this Agreement is in derogation of
      MoA&rsquo;s obligation to comply with governmental, court and law
      enforcement requests or requirements relating to your use of the
      Viarota Service.
    </p>
    <p>
      <strong>Governing Law.</strong>&nbsp;&nbsp;This Agreement and your
      relationship with Viarota shall be governed exclusively by, and will
      be enforced, construed, and interpreted exclusively in accordance with,
      the laws applicable in Greece, without regard to its conflict of law
      provisions. All disputes under this Agreement will be resolved by the
      courts of Athens in Greece. In any action or proceeding to enforce rights
      under this Agreement, the prevailing party shall be entitled to recover
      costs and attorneys&rsquo; fees.
    </p>
    <p>
      <strong>Nature of Relationship.</strong>&nbsp;&nbsp;No agency,
      partnership, joint venture, or employment relationship is created as a
      result of this Agreement and neither party has any authority of any kind
      to bind the other in any respect.
    </p>
    <p>
      <strong>Severability</strong>. If any part of this Agreement is determined
      to be invalid or unenforceable pursuant to applicable law including, but
      not limited to, the warranty disclaimers and liability limitations set
      forth above, then the invalid or unenforceable provision will be deemed
      superseded by a valid, enforceable provision that most closely matches the
      intent of the original provision and the remainder of the agreement shall
      continue in effect.
    </p>
    <p>
      <strong>CONTACT</strong>&nbsp;<strong>INFORMATION</strong>
    </p>
    <p>
      If you want more information, please contact us at{' '}
      <a href="mailto:info@avdera.gr">info@avdera.gr</a>.
    </p>
  </div>
);

export default Terms;
