import axios from 'axios';
import authStorage from '../authentication/authStorage';
const qs = require('qs');

/**
 * AXIOS CONFIG
 */
const my_backend = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : 'http://localhost:8080/';

let axiosInstance = axios.create({
  baseURL: my_backend,
});

/**
 * INTERCEPTORS
 */

/**
 * Adds interceptor to request
 *
 */
axiosInstance.interceptors.request.use(
  async (config) => {
    // add tokens and headers
    const token = await authStorage.getAuth('auth');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    console.log('\x1b[35m%s\x1b[0m', '[DATASET REQUEST] \n', config.url);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Adds interceptor to response
 *
 */
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('\x1b[35m%s\x1b[0m', '[API RESPONSE] \n', response);
    return response;
  },
  (error) => {
    return error.response;
  }
);

const fetch = (type, params) => {
  let url = '';
  switch (type) {
    case 'pois':
      url = 'pois';
      break;
    case 'filterPois':
      url = 'pois/text/' + params;
      break;
    case 'poiById':
      url = 'pois/' + params;
      break;
    case 'poiAd': 
      url = 'ad/me/' + params;
      break;
    case 'tourTemplates':
      url = 'templates';
      break;
    case 'tourTemplate':
      url = 'templates/' + params.id;
      break;
    case 'user':
      url = '/users/me';
      break;
    case 'users':
      url = '/users'
      break;
    case 'statistics':
      url = '/statistics';
      break;
    case 'subscriptions':
      url = 'points/subscriptions';
      break;
    case 'loyalties':
      url = '/points/loyalties';
      break;
    case 'statePlans':
      url = '/points/states';
      break;
    case 'subscriptionTypes':
      url = '/points/subscriptionTypes';
      break;
    case 'gainPointsRules':
      url = '/points/gainPointsRules';
      break;
    case 'redeemPointsRules':
      url = '/points/redeemPointsRules';
      break;
    default:
      break;
  }

  console.log('going to use url:', url);
  console.log('params', params);

  return axiosInstance
    .get(url, {
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
    .then((response) => {
      console.log(`response from API call: ${url}::`, response);
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const post = (type, params) => {
  let url = '';
  switch (type) {
    case 'recalculation':
      url = 'tour-suggestion/recalculation';
      break;
    case 'tourTemplate':
      url = 'templates';
      break;
    case 'pois':
      url = 'pois';
      break;
    case 'poiAd': 
      url = 'ad'
      break;
    case 'users':
      url = 'users'
      break;
    case 'subscriptions': {
      url = '/points/subscriptions';
      break;
    }
    case 'loyalties':
      url = '/points/loyalties';
      break;
    case 'gainPointsRules':
      url = '/points/gainPointsRules';
      break;
    case 'redeemPointsRules':
      url = '/points/redeemPointsRules';
      break;
    default:
      break;
  }
  console.log('going to use url:', url);

  return axiosInstance
    .post(url, params)
    .then((response) => {
      console.log('response from API:', response);
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const remove = (type, params) => {
  let url = ''
  // Use switch when more delete cases occur
  console.log('insideRemove', type, params);
  switch(type) {
    case 'pois':
      url = 'pois/' + params.id;
      break;
    case 'poiAd': 
      url = 'ad/' + params;
      break;
    case 'users':
      url = 'users/me/' + params.id
      break;
    case 'tourTemplate':
      url = 'templates/' + params.id
      break;
    case 'subscriptions': 
      url = '/points/subscriptions/' + params.id;
      break;
    case 'loyalties':
      url = '/points/loyalties/' + params.id;
      break;
    case 'gainPointsRules':
      url = '/points/gainPointsRules/' + params.id;
      break;
    case 'redeemPointsRules': 
      url = '/points/redeemPointsRules/' + params.id;
      break;
    default:
      break;
  }
  return axiosInstance
    .delete(url)
    .then((response) => {
      console.log('response from API:', response);
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const update = (type, params) => {
  let url = '';
  switch (type) {
    case 'stabilizeTour':
      url = 'templates/' + params.id;
      break;
    case 'pois':
      url = 'pois/' + params.id;
      break;
    case 'poiAd': 
      url = 'ad'
      break;
    case 'users':
      url = 'users/me';
      break;
    case 'subscriptions': 
      url = '/points/subscriptions';
      break;
    case 'loyalties':
      url = '/points/loyalties';
      break;
    case 'gainPointsRules':
      url = '/points/gainPointsRules';
      break;
    case 'redeemPointsRules':
      url = '/points/redeemPointsRules';
      break;
    default:
      break;
  }
  return axiosInstance
    .put(url, params)
    .then((response) => {
      console.log('response from API:', response);
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default {
  fetch,
  post,
  update,
  remove,
};
