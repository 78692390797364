import dataApi from "../../../services/data/dataApi";


// Manage Plans Types
export const MANAGE_ITEM_ERROR = "MANAGE_ITEM_ERROR";
export const FETCH_SUBSCRIPTIONS_SUCCESSFUL = "FETCH_SUBSCRIPTIONS_SUCCESSFUL";
export const FETCH_LOYALTIES_SUCCESSFUL = "FETCH_LOYALTIES_SUCCESSFUL";
export const FETCH_STATES_SUCCESSFUL = "FETCH_STATES_SUCCESSFUL";
export const FETCH_SUBSCRIPTION_TYPES_SUCCESSFUL = "FETCH_SUBSCRIPTION_TYPES_SUCCESSFUL";
export const FETCH_GAIN_POINTS_RULES_SUCCESSFUL = "FETCH_GAIN_POINTS_RULES_SUCCESSFUL";
export const FETCH_REDEEM_POINTS_RULES_SUCCESSFUL = "FETCH_REDEEM_POINTS_RULES_SUCCESSFUL";
export const PLANS_LOADING = "PLANS_LOADING";
export const SAVE_SUBSCRIPTIONS_SUCCESSFUL = "SAVE_SUBSCRIPTIONS_SUCCESSFUL";
export const SAVE_LOYALTIES_SUCCESSFUL = "SAVE_LOYALTIES_SUCCESSFUL";
export const SAVE_GAIN_POINTS_RULES_SUCCESSFUL = "SAVE_GAIN_POINTS_RULES_SUCCESSFUL";
export const SAVE_REDEEM_POINTS_RULES_SUCCESSFUL = "SAVE_REDEEM_POINTS_RULES_SUCCESSFUL";
export const CLEAR_SUBSCRIPTIONS = "CLEAR_SUBSCRIPTIONS";
export const CLEAR_LOYALTIES = "CLEAR_LOYALTIES";
export const CLEAR_GAIN_POINTS_RULES = "CLEAR_GAIN_POINTS_RULES";
export const CLEAR_REDEEM_POINTS_RULES = "CLEAR_REDEEM_POINTS_RULES";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_LOYALTY = "DELETE_LOYALTY";
export const DELETE_GAIN_POINTS_RULE = "DELETE_GAIN_POINTS_RULE";
export const DELETE_REDEEM_POINTS_RULE = "DELETE_REDEEM_POINTS_RULE";


const plansLoading = (payload) => {
  console.log(`calling PLANS_LOADING for ${payload}`);
  return {
    type: PLANS_LOADING,
  };
}

const setSubscriptions = (payload) => {
    return {
      type: FETCH_SUBSCRIPTIONS_SUCCESSFUL,
      payload
    }
  }

  const setLoyalties = (payload) => {
    return {
      type: FETCH_LOYALTIES_SUCCESSFUL,
      payload
    }
  }

  const setError = (payload) => {
    return {
      type: MANAGE_ITEM_ERROR,
      payload,
    };
  }

  const setPlanStates = (payload) => {
    return {
      type: FETCH_STATES_SUCCESSFUL,
      payload
    }
  }

  const setSubscriptionTypes = (payload) => {
    return {
      type: FETCH_SUBSCRIPTION_TYPES_SUCCESSFUL,
      payload
    }
  }

  const setGainPointsRules = (payload) => {
    return {
      type: FETCH_GAIN_POINTS_RULES_SUCCESSFUL,
      payload
    }
  }

  const setRedeemPointsRules = (payload) => {
    return {
      type: FETCH_REDEEM_POINTS_RULES_SUCCESSFUL,
      payload
    }
  }

  const saveSubscriptions = (payload) => {
    console.log('calling saveLoyalties', payload);
    return {
      type: SAVE_SUBSCRIPTIONS_SUCCESSFUL,
      payload,
    };
  }

  const saveLoyalties = (payload) => {
    console.log('calling saveLoyalties', payload);
    return {
      type: SAVE_LOYALTIES_SUCCESSFUL,
      payload,
    };
  }

  const saveGainPoints = (payload) => {
    console.log('calling saveGainPoints', payload);
    return {
      type: SAVE_GAIN_POINTS_RULES_SUCCESSFUL,
      payload,
    };
  }

  const saveRedeemPoints = (payload) => {
    console.log('calling saveRedeemPoints', payload);
    return {
      type: SAVE_REDEEM_POINTS_RULES_SUCCESSFUL,
      payload
    }
  }

  export const clearLoyalties = () => {
    console.log('calling clearLoyalties');
    return {
      type: CLEAR_LOYALTIES
    }
  }

  export const clearSubscriptions = () => {
    console.log('calling clearSubscriptions');
    return {
      type: CLEAR_SUBSCRIPTIONS
    }
  }

  export const clearGainPoints = () => {
    console.log('calling clearGainPoints');
    return {
      type: CLEAR_GAIN_POINTS_RULES
    }
  }

  export const clearRedeemPoints = () => {
    console.log('calling clearRedeemPoints');
    return {
      type: CLEAR_REDEEM_POINTS_RULES
    }
  }

  const deleteSubscription = () => {
    console.log('calling deleteSubscription');
    return {
      type: DELETE_SUBSCRIPTION
    }
  }

  const deleteLoyalty = () => {
    console.log('calling deleteLoyalty');
    return {
      type: DELETE_LOYALTY
    }
  }

  const deleteGainPointsRule = () => {
    console.log('calling deleteGainPointsRule');
    return {
      type: DELETE_GAIN_POINTS_RULE
    }
  }

  const deleteRedeemPointsRule = () => {
    console.log('calling deleteRedeemPointsRule');
    return {
      type: DELETE_REDEEM_POINTS_RULE
    }
  }

export const fetchAllSubscriptions = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Subscription"));
        const params = {...payload};
        const response = await dataApi.fetch('subscriptions', params);
        if(response.status === 200) {
          console.log('fetchAllSubscriptions response:', response);
          let action = setSubscriptions(response.data);
          console.log('fetchAllSubscriptions action to dispatch', action);
          dispatch(action);
        } else {
            dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    }
  }

  export const fetchAllLoyalties = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Loyalty"));
        const params = {...payload};
        const response = await dataApi.fetch('loyalties', params);
        if(response.status === 200) {
          console.log('fetchAllLoyalties response:', response);
          let action = setLoyalties(response.data);
          console.log('fetchAllLoyalties action to dispatch', action);
          dispatch(action);
  
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        
        dispatch(setError('Something went wrong. Please try later!'));
      }
    }
  }

  export const fetchPlanStates = (payload) => {
    return async (dispatch) => {
      try {
        const params = {...payload};
        const response = await dataApi.fetch('statePlans', params);
        if(response.status === 200) {
          console.log('fetchPlanStates response:', response);
          let action = setPlanStates(response.data);
          console.log('fetchPlanStates action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) { 
        dispatch(setError('Something went wrong. Please try later!'));
      }
    }
  }

  export const fetchSubscriptionTypes = (payload) => {
    return async (dispatch) => {
      try {
        const params = {...payload};
        const response = await dataApi.fetch('subscriptionTypes', params);
        if(response.status === 200) {
          console.log('fetchSubscriptionTypes response:', response);
          let action = setSubscriptionTypes(response.data);
          console.log('fetchSubscriptionTypes action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) { 
        dispatch(setError('Something went wrong. Please try later!'));
      }
    }
  }

  export const fetchGainPointsRules = (payload) => {
    return async (dispatch) => {
      try {
        const mObject = {...payload};
        const {loading, ...params} = mObject;
        if(loading) {
          dispatch(plansLoading("GainPointsRules"));
        }
        const response = await dataApi.fetch('gainPointsRules', params);
        if(response.status === 200) {
          console.log('fetchGainPointsRules response:', response);
          let action = setGainPointsRules(response.data);
          console.log('fetchGainPointsRules action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) { 
        dispatch(setError('Something went wrong. Please try later!'));
      }
    }
  }

  export const fetchRedeemPointsRules = (payload) => {
    return async (dispatch) => {
      try {
        const mObject = {...payload};
        const {loading, ...params} = mObject;
        if(loading) {
          dispatch(plansLoading("RedeemPointsRules"));
        }
        const response = await dataApi.fetch('redeemPointsRules', params);
        if(response.status === 200) {
          console.log('fetchRedeemPointsRules response:', response);
          let action = setRedeemPointsRules(response.data);
          console.log('fetchRedeemPointsRules action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) { 
        dispatch(setError('Something went wrong. Please try later!'));
      }
    }
  }

  export const saveSubscriptionsToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Subscription"));
        let response;
        response = await dataApi.post('subscriptions', payload);
        if (response.status === 200) {
          console.log('saveSubscriptionsToDB response:', response);
          let action = saveSubscriptions(response.data);
          console.log('saveSubscriptionsToDB action to dispatch:', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const updateSubscriptionsToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Subscription"));
        let response;
        response = await dataApi.update('subscriptions', payload);
        if (response.status === 200) {
          console.log('updateSubscriptionsToDB response:', response);
          let action = saveSubscriptions(response.data);
          console.log('updateSubscriptionsToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const deleteSubscriptionFromDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Subscription"));
        let response;
        response = await dataApi.remove('subscriptions', payload);
        if (response.status === 200) {
          console.log('deleteSubscriptionFromDB response:', response);
          let action = deleteSubscription()
          console.log('deleteSubscriptionFromDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }
  
  export const saveLoyaltiesToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Loyalty"));
        let response;
        response = await dataApi.post('loyalties', payload);
        if (response.status === 200) {
          console.log('saveLoyaltiesToDB response:', response);
          let action = saveLoyalties(response.data);
          console.log('saveLoyaltiesToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const updateLoyaltiesToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Loyalty"));
        let response;
        response = await dataApi.update('loyalties', payload);
        if (response.status === 200) {
          console.log('updateLoyaltiesToDB response:', response);
          let action = saveLoyalties(response.data);
          console.log('updateLoyaltiesToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const deleteLoyaltyFromDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("Loyalty"));
        let response;
        response = await dataApi.remove('loyalties', payload);
        if (response.status === 200) {
          console.log('deleteLoyaltyToDB response:', response);
          let action = deleteLoyalty()
          console.log('deleteLoyaltyToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const saveGainPointsRulesToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("GainPointsRules"));
        let response;
        response = await dataApi.post('gainPointsRules', payload);
        if (response.status === 201) {
          console.log('saveGainPointsRulesToDB response:', response);
          let action = saveGainPoints(response.data);
          console.log('saveGainPointsRulesToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const updateGainPointsRulesToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("GainPointsRules"));
        let response;
        response = await dataApi.update('gainPointsRules', payload);
        if (response.status === 200) {
          console.log('updateGainPointsRulesToDB response:', response);
          let action = saveGainPoints(response.data);
          console.log('updateGainPointsRulesToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const deleteGainPointsRuleFromDB = (payload) => {
    return async (dispatch) => {
      try {
        // dispatch(plansLoading("GainPointsRules"));
        let response;
        response = await dataApi.remove('gainPointsRules', payload);
        if (response.status === 204) {
          console.log('deleteGainPointsRuleFromDB response:', response);
          let action = deleteGainPointsRule()
          console.log('deleteGainPointsRuleFromDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const saveRedeemPointsRulesToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("RedeemPointsRules"));
        let response;
        response = await dataApi.post('redeemPointsRules', payload);
        if (response.status === 201) {
          console.log('saveRedeemPointsRulesToDB response:', response);
          let action = saveRedeemPoints(response.data);
          console.log('saveRedeemPointsRulesToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const updateRedeemPointsRulesToDB = (payload) => {
    return async (dispatch) => {
      try {
        dispatch(plansLoading("RedeemPointsRules"));
        let response;
        response = await dataApi.update('redeemPointsRules', payload);
        if (response.status === 200) {
          console.log('updateRedeemPointsRulesToDB response:', response);
          let action = saveRedeemPoints(response.data);
          console.log('updateRedeemPointsRulesToDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }

  export const deleteRedeemPointsRuleFromDB = (payload) => {
    return async (dispatch) => {
      try {
        // dispatch(plansLoading("RedeemPointsRules"));
        let response;
        response = await dataApi.remove('redeemPointsRules', payload);
        if (response.status === 204) {
          console.log('deleteRedeemPointsRuleFromDB response:', response);
          let action = deleteRedeemPointsRule()
          console.log('deleteRedeemPointsRuleFromDB action to dispatch', action);
          dispatch(action);
        } else {
          dispatch(setError('Something went wrong. Please try later!'));
        }
      } catch (error) {
        dispatch(setError('Something went wrong. Please try later!'));
      }
    };
  }