import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

// ICONS
import ExclamationIcon from 'mdi-react/ExclamationIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';

// CUSTOM SCSS
import './Tour.scss';

// ICONS
import DeleteIcon from 'mdi-react/DeleteIcon';
import { Button } from 'reactstrap';

const Tour = props => {
  const calculateDuration = duration => {
    let calcDuration;
    if (duration > 3600) {
      let totalMinutes = Math.floor(duration / 60);
      let remainingMinutes = totalMinutes % 60;
      let totalHours = Math.floor(duration / 3600);
      calcDuration =
        totalHours +
        'h ' +
        (remainingMinutes === 0 ? '' : remainingMinutes + 'm');
    } else {
      calcDuration = Math.floor(duration / 60) + 'm';
    }
    return calcDuration;
  };

  return (
    <div className={`tour_card ${props.className}`}>
      <h4 className="tour_card_title">
        <Tooltip
          title={props.tour.title}
          aria-label="tour-title"
          placement="top"
        >
          <span>{props.tour.title}</span>
        </Tooltip>
      </h4>
      <div className="tour_info">
        <span>Pois:</span> {props.tour.waypointList.length}
      </div>
      <div className="tour_info">
        <span>Duration:</span> {calculateDuration(props.tour.duration)}
      </div>
      {props.tour.transportationType && (
        <div className="tour_info">
          <span>Transportation:</span>{' '}
          {props.tour.transportationType.toLowerCase()}
        </div>
      )}
      <div className="d-flex justify-content-end">
        <Link className="outline-link" to={`/tourTemplates/${props.tour.id}`}>
          Edit/View
        </Link>
      </div>
      <div className="vertical_buttons">
        <Button
            className="btn-delete-poi"
            onClick={() => props.onDeleteTemplateTour()}
          >
              <DeleteIcon />
          </Button>
      </div>
      <div className="indicators_container d-flex">
        {props.tour.inactive && (
          <Tooltip title="Inactive" aria-label="inactive" placement="top">
            <div className="indicator d-flex justify-content-center align-items-center">
              <EyeOffOutlineIcon />
            </div>
          </Tooltip>
        )}
        {props.tour.unstable && (
          <Tooltip title="Modified" aria-label="modified" placement="top">
            <div className="indicator d-flex justify-content-center align-items-center">
              <ExclamationIcon />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Tour;
