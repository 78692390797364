import { CLEAR_GAIN_POINTS_RULES, CLEAR_LOYALTIES, CLEAR_REDEEM_POINTS_RULES, CLEAR_SUBSCRIPTIONS, DELETE_GAIN_POINTS_RULE, DELETE_LOYALTY, DELETE_REDEEM_POINTS_RULE, DELETE_SUBSCRIPTION, FETCH_GAIN_POINTS_RULES_SUCCESSFUL, FETCH_LOYALTIES_SUCCESSFUL, FETCH_REDEEM_POINTS_RULES_SUCCESSFUL, FETCH_STATES_SUCCESSFUL, FETCH_SUBSCRIPTIONS_SUCCESSFUL, FETCH_SUBSCRIPTION_TYPES_SUCCESSFUL, MANAGE_ITEM_ERROR, PLANS_LOADING, SAVE_GAIN_POINTS_RULES_SUCCESSFUL, SAVE_LOYALTIES_SUCCESSFUL, SAVE_REDEEM_POINTS_RULES_SUCCESSFUL, SAVE_SUBSCRIPTIONS_SUCCESSFUL } from "../../actions/managePlans/managePlansActions";

const initialState = {
    error: '',
    modal: false,
    fetching: false,
    subscriptionsData: [],
    savedSubscription: {},
    loyaltiesData: [],
    savedLoyalty: {},
    gainPointsRulesData: [],
    savedGainPointsRules: {},
    redeemPointsRulesData: [],
    savedRedeemPointsRule: {},
    planStates: [],
    subscriptionTypes: [],
    saved: false,
    deleted: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_SUBSCRIPTIONS_SUCCESSFUL: {
            return {
              ...state,
              subscriptionsData: action.payload,
              error: '',
              fetching: false,
            };
          }

        case FETCH_LOYALTIES_SUCCESSFUL: {
            return {
                ...state,
                loyaltiesData: action.payload,
                error: '',
                fetching: false,
                saved: false,
                deleted: false,
            };
        }

        case MANAGE_ITEM_ERROR: {
            return {
                ...state,
                error: action.payload,
                fetching: false,
                saved: false
            };
        }

        case FETCH_STATES_SUCCESSFUL: {
            return {
                ...state,
                planStates: action.payload,
                error: '',
                fetching: false,
            }
        }

        case FETCH_SUBSCRIPTION_TYPES_SUCCESSFUL: {
            return {
                ...state,
                subscriptionTypes: action.payload,
                error: '',
                fetching: false,
            }
        }

        case FETCH_GAIN_POINTS_RULES_SUCCESSFUL: {
            return {
                ...state,
                gainPointsRulesData: action.payload,
                error: '',
                fetching: false,
                saved: false,
                deleted: false,
            }
        }

        case FETCH_REDEEM_POINTS_RULES_SUCCESSFUL: {
            return {
                ...state,
                redeemPointsRulesData: action.payload,
                error: '',
                fetching: false,
                saved: false,
                deleted: false,
            }
        }

        case SAVE_SUBSCRIPTIONS_SUCCESSFUL: {
            return {
                ...state,
                error: '',
                fetching: false,
                saved: true,
                savedSubscription: action.payload
              };
        }

        case SAVE_LOYALTIES_SUCCESSFUL: {
            return {
              ...state,
              error: '',
              fetching: false,
              saved: true,
              savedLoyalty: action.payload
            };
          }

        case SAVE_GAIN_POINTS_RULES_SUCCESSFUL: {
            return {
                ...state,
                error: '',
                fetching: false,
                saved: true,
                savedGainPointsRules: action.payload
            };
        }

        case SAVE_REDEEM_POINTS_RULES_SUCCESSFUL: {
            return {
                ...state,
                error: '',
                fetching: false,
                saved: true,
                savedRedeemPointsRule: action.payload
            };
        }

        case CLEAR_SUBSCRIPTIONS: {
            return {
                ...state,
                subscriptionsData: [],
                error: '',
                fetching: false
            }
        }

        case CLEAR_LOYALTIES: {
            return {
                ...state,
                loyaltiesData: [],
                error: '',
                fetching: false
            }
        }

        case CLEAR_GAIN_POINTS_RULES: {
            return {
                ...state,
                gainPointsRulesData: [],
                error: '',
                fetching: false
            }
        }

        case CLEAR_REDEEM_POINTS_RULES: {
            return {
                ...state,
                redeemPointsRulesData: [],
                error: '',
                fetching: false
            }
        }

        case DELETE_SUBSCRIPTION: {
            return {
                ...state,
                error: '',
                fetching: false,
                deleted: true
            }
        }

        case DELETE_LOYALTY: {
            return {
                ...state,
                error: '',
                fetching: false,
                deleted: true
            }
        }

        case DELETE_GAIN_POINTS_RULE: {
            return {
                ...state,
                error: '',
                fetching: false,
                deleted: true
            }
        }

        case DELETE_REDEEM_POINTS_RULE: {
            return {
                ...state,
                error: '',
                fetching: false,
                deleted: true
            }
        }

        case PLANS_LOADING: {
            return {
                ...state,
                error: '',
                fetching: true,
                saved: false,
                deleted: false
            }
        }

        default:
            return state;
  }
}