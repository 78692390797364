import React from 'react'
import DeleteIcon from 'mdi-react/DeleteIcon'
import EditIcon from 'mdi-react/EditIcon'
import './PlanItem.scss'
import { Button } from 'reactstrap'

const PlanListItem = (props) => {
    const {item, onEditClick, onDeleteClick, actionType} = props;

    /**
     * Function that get a word and capitalize its first letter
     * @param {*} word given word to modify
     * @returns capitalized word
     */
    const capitalizeFLetter = (word) => {
      if(word) {
        return word[0].toUpperCase() + word.slice(1);
      }
    }

    /**
     * Function that render the UI according to property "type"
     * @param {*} type type of plan to render ("Subscription", "Loyalty", "GainPoints", "RedeemPoints")
     * @param {*} item the whole item
     * @returns UI according to type
     */
    const renderByActionType = (type, item) => {
        if(type === "Subscription") {
            return (<>
                <div className="title">
                    <span><b>Title: </b></span>
                    <span>{item?.title}</span>
                </div>
                <div className="cost">
                    <span><b>Cost: </b></span>
                    <span>{`${item?.cost}€`}</span>
                </div>
                <div className="subscription-type">
                    <span><b>Type: </b></span> 
                    <span>{capitalizeFLetter(item?.subscriptionType?.title)}</span>
                </div>
                <div className="state">
                    <span><b>State: </b></span> 
                    <span>{capitalizeFLetter(item?.state?.stateText)}</span>
                </div>
            </>
            )
        }
        if(type === 'Loyalty') {
            return(<>
              <div className="title">
                <span><b>Title: </b></span>
                <span>{item?.title}</span>
              </div>
              <div className="description">
                <span><b>Description: </b></span>
                <span>{item?.description}</span>
              </div>
              <div className="state">
                <span><b>State: </b></span> 
                <span>{capitalizeFLetter(item?.state?.stateText)}</span>
              </div>
            </>)
        }
        if(type === "GainPoints") {
          return(<>
            <div className="description">
              <span><b>Description: </b></span>
              <span>{`${item?.cost}€ gain ${item?.points} points`}</span>
            </div>
            <div className="cost">
              <span><b>Cost: </b></span>
              <span>{`${item?.cost}€`}</span>
            </div>
            <div className="points">
              <span><b>Points: </b></span>
              <span>{item?.points}</span>
            </div>
          </>)
        }
        if(type === "RedeemPoints") {
          return(<>
            <div className="description">
              <span><b>Description: </b></span>
              <span>{`${item?.points} points give ${item?.discount}€ discount`}</span>
            </div>
            <div className="discount">
              <span><b>Discount: </b></span>
              <span>{`${item?.discount}€`}</span>
            </div>
            <div className="points">
              <span><b>Points: </b></span>
              <span>{item?.points}</span>
            </div>
          </>)
        }
        return <></>;
    }
  return (
    <div className="plan-item-container d-flex align-items-center">
        <div className="plan-item">
            {renderByActionType(actionType, item)}
        </div>
        <div className="vertical_buttons d-flex flex-column justify-content-center">
        {onEditClick && (
          <Button
            className="btn-edit-user" 
            onClick={() => onEditClick()}>
            <EditIcon />
          </Button>
        )}
        {onDeleteClick && (
          <Button
            className="btn-delete-user"
            onClick={() => onDeleteClick()}
          >
            <DeleteIcon />
          </Button>
        )}
      </div>
    </div>
  )
}

export default PlanListItem
