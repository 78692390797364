import {
  FETCH_POIS_SUCCESSFUL,
  POIS_LOADING,
  SAVE_POI_SUCCESSFUL,
  DELETE_POI_SUCCESSFUL,
  POIS_API_ERROR,
  TOGGLE_POI_INFO_WINDOW,
  BUSINESS_POIS_LOADING,
  FETCH_BUSINESS_POIS_SUCCESSFUL,
  FETCH_SUBSCRIPTIONS_SUCCESSFUL,
  FETCH_LOYALTIES_SUCCESSFUL,
  CLEAR_SAVED_POI,
  FETCH_POI_AD_SUCCESSFUL,
  CLEAR_POI_AD
} from '../../actions/pois/poisActions';

const initialState = {
  error: '',
  modal: false,
  fetching: false,
  data: [],
  businessData: [],
  saved: false,
  deleted: false,
  savedPoi: {},
  subscriptions: [],
  loyalties: [],
  poiAd: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case POIS_LOADING: {
      console.log('adding poi:', action.payload);
      return {
        ...state,
        error: '',
        fetching: true,
        saved: false,
        deleted: false
      };
    }

    case BUSINESS_POIS_LOADING: {
      console.log('adding business:', action.payload);
      return {
        ...state,
        error: '',
        fetching: true,
        saved: false,
        deleted: false
      };
    }

    case SAVE_POI_SUCCESSFUL: {
      return {
        ...state,
        error: '',
        fetching: false,
        saved: true,
        savedPoi: action.payload
      };
    }

    case DELETE_POI_SUCCESSFUL: {
      return {
        ...state,
        error: '',
        fetching: false,
        deleted: true
      };
    }

    case POIS_API_ERROR: {
      return {
        ...state,
        error: action.payload,
        fetching: false,
        saved: false
      };
    }

    case FETCH_POIS_SUCCESSFUL: {
      console.log('inside FETCH_POIS_SUCCESSFUL', action.payload);
      return {
        ...state,
        data: action.payload,
        error: '',
        fetching: false,
        saved: false
      };
    }

    case FETCH_BUSINESS_POIS_SUCCESSFUL: {
      console.log('inside FETCH_BUSINESS_POIS_SUCCESSFUL', action.payload);
      return {
        ...state,
        businessData: action.payload,
        error: '',
        fetching: false,
        saved: false
      };
    }

    case FETCH_SUBSCRIPTIONS_SUCCESSFUL: {
      console.log('inside FETCH_SUBSCRIPTIONS_SUCCESSFUL', action.payload);
      return {
        ...state,
        subscriptions: action.payload,
        error: '',
        fetching: false,
      };
    }

    case FETCH_LOYALTIES_SUCCESSFUL: {
      console.log('inside FETCH_LOYALTIES_SUCCESSFUL', action.payload);
      return {
        ...state,
        loyalties: action.payload,
        error: '',
        fetching: false,
      };
    }

    case FETCH_POI_AD_SUCCESSFUL: {
      return {
        ...state,
        poiAd: action.payload
      }
    }

    case CLEAR_SAVED_POI: {
      return {
        ...state,
        savedPoi: {}
      }
    }

    case CLEAR_POI_AD: {
      return {
        ...state,
        poiAd: {}
      }
    }

    case TOGGLE_POI_INFO_WINDOW: {
      console.log('inside TOGGLE_POI_INFO_WINDOW', action.payload);

      const id = action.payload;

      let newData = state.data.map(poi => {
        if (poi.id === id) {
          poi.isOpen = !poi.isOpen;
        }
        return poi;
      });

      return {
        ...state,
        data: newData,
        error: '',
        fetching: false,
        saved: false
      };
    }

    default:
      return state;
  }
}
