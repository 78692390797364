import React, { Component } from 'react'
import { clearUsers, createUserFromAdmin, deleteUserFromDB, getAllUsers, saveUserToDB } from '../../redux/actions/users/usersActions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Error from '../../components/Error/Error';
import UsersList from '../../components/UsersList/UsersList';
import { INITIAL_USER_FORM_FIELDS } from '../../shared/utils/utils';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: INITIAL_USER_FORM_FIELDS,
            modal: false,
            confirmDialogOpen: false,
            userToDelete: null,
            canEdit: true,
            editMode: false
        }
    }

    componentDidMount() {
        this.props.getUsers();
    }

    componentDidUpdate(prevProps) {
        const { saved, deleted } = this.props.users;
        // Check if there is a saved/deleted poi to start fetch pois from the beginning otherwise add new pois to the previous ones
        if (
          (saved !== prevProps.users.saved && saved) ||
          (deleted !== prevProps.users.deleted && deleted)
        ) {
          console.log('TRIGGERED');
            this.props.clearUsers();
            this.props.getUsers();
        }
        console.log('SELECTED_USER', this.state.selectedUser);
        console.log('USERS', this.props.users)
      }
    
      componentWillUnmount() {
        this.props.clearUsers();
      }

    /**
   * Handles changes from user's form
   *
   * @param {*} event
   */
  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState((prevState) => {
      let tempUser = Object.assign({}, prevState.selectedUser); // creating copy of state variable
      tempUser[name] = value;
      return { selectedUser: tempUser };
    });
  };

  /**
   * Save new/edited user to DB
   */
  saveUser = (user) => {
    console.log('USER_TO_SAVE_EDIT', user, "UPDATE_MODE", this.state.editMode);
    this.props.clearUsers();
    this.props.saveUserToDB({user, update: this.state.editMode});
    this.toggle();
    this.setState({ editMode: false });
  };

  createNewUser = (user) => {
    console.log('USER_TO_CREATE', user, "UPDATE_MODE", this.state.editMode);
    this.props.clearUsers();
    this.props.createUserFromAdmin({user, actionType: "CREATE"});
    this.toggle();
    this.setState({ editMode: false });
  }

    onItemClick = (item, canUserEdit) => {
      console.log('item', item, "canuserEdit", canUserEdit)
        this.setState(
          {
            selectedUser: item,
            canEdit: canUserEdit,
            editMode: true
          },
          () => {
            this.setState({ modal: true });
          }
        );
      };

    onItemDelete = () => {
      console.log('going to delete user', this.state.userToDelete);
      this.props.deleteUserFromDB(this.state.userToDelete);
      this.setState({ confirmDialogOpen: false });
    }

    openConfirmationDialog = (user) => {
      this.setState({ confirmDialogOpen: true, userToDelete: user });
    };

    onCancel = () => {
      this.setState({ confirmDialogOpen: false });
    };

    toggle = () => {
        this.setState((prevState) => {
          //when you close the modal, clear the previous changes
          if (prevState.modal) {
            console.log('modalOpen', prevState.modal, "->", !prevState.modal)
            return {
              modal: !prevState.modal,
              selectedUser: INITIAL_USER_FORM_FIELDS,
              canEdit: true,
              editMode: false
            };
          } else {
            return { modal: !prevState.modal };
          }
        });
      };

  render() {
    return (
        <Container className="dashboard pois_dashboard">
        <Row>
          <Col>
            <div className="page_title_container">
              <h3 className="page_title">List of Users</h3>
              <h4 className="page_subtitle">
                In this section you can manage users. You can create, edit and delete users.
              </h4>
            </div>

            {this.state.confirmDialogOpen && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={this.onItemDelete}
                onCancel={this.onCancel}
                focusCancelBtn
              >
                You will not be able to undo this action!
              </SweetAlert>
            )}

            <UsersList
                users={this.props.users}
                currentLoggedUser={this.props.loggedUser}
                modal={this.state.modal}
                selectedUser={this.state.selectedUser}
                toggle={this.toggle}
                onItemDelete={this.openConfirmationDialog}
                onItemClick={this.onItemClick}
                handleChange={(event) => this.handleChange(event)}
                saveUser={(user) => this.saveUser(user)}
                createUser={(user) => this.createNewUser(user)}
                btn="New User"
                canEdit={this.state.canEdit}
                xl={3}
                lg={4}
                md={6}
                s={12}
            >

            </UsersList>
          </Col>
        </Row>

        <Error
          error={this.props.users.error}
          page={true}
          onErrorClose={() => this.props.clearUsers()}
        />
      </Container>
    )
  }
}
const mapStateToProps = (state) => {
    return {
      users: state.usersManage,
      loggedUser: state.user
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: (payload) => dispatch(getAllUsers(payload)),
        saveUserToDB: (payload) => dispatch(saveUserToDB(payload)),
        clearUsers: (payload) => dispatch(clearUsers(payload)),
        deleteUserFromDB: (payload) => dispatch(deleteUserFromDB(payload)),
        createUserFromAdmin: payload => dispatch(createUserFromAdmin(payload)),
    };
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
