import React from 'react'
import Modal from '../Modal/Modal'
import Scrollbars from 'react-custom-scrollbars';
import { Col, Row } from 'reactstrap';
import Loader from '../Loader/Loader';
import User from '../User/User';
import NewUser from '../NewUser/NewUser';

const UsersList = (props) => {
  return (
    <>
    <div className="filters_container d-flex justify-content-between">
        <Modal
          isOpen={props.modal}
          color="success"
          title={props.canEdit ? "Create New User" : "Edit User"}
          header
          btn={props.btn}
          className="grey"
          toggle={() => props.toggle()}
          backdrop="static"
        >
          <NewUser
            toggle={() => props.toggle()}
            user={props.selectedUser}
            handleChange={event => props.handleChange(event)}
            saveUser={user => props.saveUser(user)}
            createUser={user => props.createUser(user)}
            canEdit={props.canEdit}
          />
        </Modal>
        </div>
        <Row className="pois_container">
        {!props.users.saved && (
          <Loader loading={props.users.loading} className="full_page" />
        )}
        <Scrollbars
        //   onScrollFrame={event => getMorePois(event)}
          renderTrackHorizontal={props => (
            <div {...props} className="track-horizontal" />
          )}
        >
          <Col>
            <Row>
              {props.users.usersData.map(user => {
                return (
                  <Col
                    xl={props.xl}
                    lg={props.lg}
                    md={props.md}
                    s={props.s}
                    key={user.id}
                  >
                    {/* <span>{user.username}</span> */}
                    <User
                      currentLoggedUser={props.currentLoggedUser}
                        user={user}
                        onEditClick={
                            props.onItemClick
                              ? () => props.onItemClick(user, false)
                              : null
                        }
                        onDeleteClick={
                            props.onItemDelete
                              ? () => props.onItemDelete(user)
                              : null
                        }
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Scrollbars>
      </Row>
    
    </>
  )
}

export default UsersList
